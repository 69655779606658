@import "../../../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../../../Styles/variables.scss";

.admin_view_tiles {
    width: 100%;
    .admin_view_tiles_tiles_container {
        background: $white;
        width: 100%;
        border-radius: 6px;
        margin-bottom: 20px;
    }
}