@import "../../../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../../../Styles/variables.scss";

.admin_add_product_container {
    width: 100%;
    h4 {
        color: $contentColor;
    }
    .admin_add_product_preview_container {
        @include flex(column, center, flex-end);
        width: 50%;
        margin-left: 15px;
        .admin_add_product_preview {
            @include flex(column, flex-start);
            width: 270px;
            height: 480px;
            overflow: hidden;
            .admin_add_product_preview_content {
                @include flex(column, flex-start);
                background: url("../../../../../../../../../../Styles/Images/Store/Product_Background_Outer.jpg");
                width: 270px;
                height: 450px;
                margin: 0 5px 10px 5px;
                padding: 10px;
                background-position: center !important;
                background-size: 100% 100% !important;
                .admin_add_product_preview_img_container {
                    @include flex(column);
                    @include shadow();
                    background: url("../../../../../../../../../../Styles/Images/Store/Product_Background_Inner.png");
                    width: 254px;
                    height: 286px;
                    margin-top: -2px;
                    margin-bottom: 10px;
                    background-position: center !important;
                    background-size: 100% 100% !important;
                    .fading_img {
                        width: 250px;
                        height: 280px;
                        opacity: .5;
                        background-position: center !important;
                        background-size: 100% 100% !important;
                        transition: opacity .5s;
                        -webkit-transition: opacity .5s;
                        margin-right: 2px;
                        &:hover {
                            cursor: pointer;
                            opacity: 1;
                        }
                    }
                }
                .admin_add_product_preview_info_container {
                    width: 100%;
                    height: 130px;
                    word-wrap: break-word;
                    padding: 0 5px;
                    h4 {
                        color: $titleColor;
                        text-align: center;
                        margin-top: 0;
                        max-height: 40px;
                        word-wrap: break-word;
                        overflow: hidden;
                    }
                    p {
                        color: $contentColor;
                        text-align: center;
                        height: 80px;
                        word-wrap: break-word;
                        overflow: hidden;
                    }
                }
            }
        }
    }
    .admin_add_product_btn_container {
        @include flex(row, space-between);
        width: 100%;
        margin-bottom: 30px;
        button {
            background: $white;
            border: none;
            width: 85px;
            height: 30px;
            border-radius: 6px;
            &:hover {
                cursor: pointer;
                background-color: $offWhite;
            }
            &:focus {
                outline: none;
            }
        }
    }
    
    .admin_add_product_products_container {
        background: $white;
        width: 100%;
        border-radius: 6px;
    }
}

#admin_add_product_add_btn {
    position: relative;
    font-size: 13px;
    transition: all 1s;
}