@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.admin_new {
    width: 970px;
    max-width: 970px;
}

.new_user_panel {
    position: relative;
    z-index: 1;
    text-align: left;
    .new_user_header {
        background: url("../../Styles/Images/Panel/title.jpg");
        color: $titleColor;
        padding: 10px;
    }
    .new_user_content {
        @include flex(column, center, flex-start);
        background: url("../../Styles/Images/Panel/middle.jpg");
        padding: 10px;
        .text_input_container {
            @include flex(row);
            width: 100%;
            margin-bottom: 50px;
            &:nth-last-child(1) {
                margin-bottom: 0;
            }
            p {
                width: 22%;
                margin: 0 10px 0 0;
                color: $contentColor;
            }
            input {
                width: 75%;
                height: 30px;
                outline: none;
            }
        }
        .password_input {
            @include flex(row);
            position: relative;
            width: 100%;
            img {
                position: absolute;
                right: 35px;
                width: 30px;
                height: 30px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .new_user_btn_container {
            width: 100%;
            float: left;
            button {
                float: right;
                border: none;
                color: $contentColor;
                width: 100px;
                background: $btnPrimaryBackground;
                padding: 10px;
                border-radius: 6px;
                outline: none;
                &:hover {
                    cursor: pointer;
                    background: $btnPrimaryHover;
                }
            }
        }
    }
    .new_user_panel_bottom {
        background: url("../../Styles/Images/Panel/bottom_none.jpg");
        width: 100%;
        height: 3vh;
    }
}

#finish_setup_btn {
    font-size: 13px;
    transition: all 1s;
    position: relative;
}