@import "../../Styles/variables.scss";

.terms {
    width: 970px;
    max-width: 970px;
}

.wall_fade_in {
    background: url("../../Styles/Images/Panel/fade_in.jpg");
    width: 970px;
    height: 138px;
}

.wall_content {
    padding: 10px 15px 55px;
    margin-top: -138px;
    overflow: hidden;
    color: $contentColor;
    min-height: 140px;
    font-size: 110%;
}

.ol_a, .ol_i, .ul_bullet {
    padding-inline-start: 40px;
}

.ol_a {
    list-style-type: lower-alpha;
}

.ol_i {
    list-style-type: lower-roman;
}

.wall_mid_footer {
    width: 970px;
    height: 184px;
    margin-top: -184px;
}

.wall_mid_content {
    padding: 10px 15px 55px;
    overflow: hidden;
    color: $contentColor;
    min-height: 140px;
    font-size: 110%;
}

.ul_bullet {
    list-style-type: disc;
}

.wall_fade_out {
    background: url("../../Styles/Images/Panel/fade_out_dark.png");
    width: 970px;
    height: 106px;
    margin-top: -184px;
}

.wall_footer_none {
    background: url("../../Styles/Images/Panel/bottom_none.jpg");
    width: 970px;
    height: 78px;
}