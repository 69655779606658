@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.gallery_img_model_panel {
    display: none;
    position: fixed;
    background: rgba(10,10,10,.7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10;
    transition: opacity .1s;
    #img_view_container {
        position: absolute;
        display: inline-block;
        background: #222;
        padding: 20px;
        width: 300px;
        height: 150px;
        top: 50%;
        left: 50%;
        margin-top: -95px;
        margin-left: -170px;
        transition: all .4s;
        &:focus {
            outline: none;
        }
        .img_container {
            opacity: 0;
            transition: opacity .3s 
        }
        .img_arrow {
            position: absolute;
            background: rgba(32,32,32,.6);
            top: 0;
            bottom: 0;
            width: 100px;
            opacity: 0;
            transition: opacity .2s;
            max-width: 50%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 40px 43px;
            &:hover {
                cursor: pointer;
                opacity: 1;
            }
        }
        #previous_img_arrow {
            left: 20px;
            background-image: url("../../../../Styles/Images/pagination/previous.png");
        }
        #next_img_arrow {
            right: 20px;
            background-image: url("../../../../Styles/Images/pagination/next.png");
        }
    }
}

.model_active {
    display: block
}

.model_inactive {
    display: none
}

.no_select {
    -moz-user-select: -moz-none;
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}