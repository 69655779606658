@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_media_gallery_container {
    @include flex(row, space-between);
    background: $white;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid $lightGrey;
    &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
    &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    p {
        margin: 0;
    }
    div {
        button {
            background: $white;
            border-radius: 6px;
            border: 1px solid $black;
            padding: 3px 5px;
            &:focus {
                outline: none;
            }
            &:hover {
                cursor: pointer;
                background: $offWhite;
            }
            &:nth-child(1) {
                margin-right: 10px;
            }
        }
    }
}

.admin_delete_galleries_btn {
    position: relative;
    font-size: 13px;
    transition: all 1s;
    width: 50px;
    height: 22px;
    &::before {
        top: 25% !important;
        left: 40% !important;
    }
}