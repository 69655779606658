@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.store_product {
    @include flex(column, flex-start);
    position: relative;
    background-image: url("../../../../../../Styles/Images/Store/Product_Background_Outer.jpg");
    width: 270px;
    margin: 0 5px 10px 5px;
    background-position: center !important;
    background-size: 100% 100% !important;
    .product_content {
        @include flex(column, flex-start);
        width: 270px;
        background-position: center !important;
        background-size: 100% 100% !important;
        padding: 10px;
        padding-bottom: 0;
        .product_img_container {
            @include flex(column);
            @include shadow();
            background-image: url("../../../../../../Styles/Images/Store/Product_Background_Inner.png");
            width: 254px;
            height: 286px;
            margin-top: -2px;
            margin-bottom: 10px;
            background-position: center !important;
            background-size: 100% 100% !important;
            img {
                width: 250px;
                height: 280px;
                background-position: center !important;
                background-size: 100% 100% !important;
                opacity: 1 !important;
            }
        }
        .product_info_container {
            width: 250px;
            margin-bottom: 5px;
            h4 {
                color: $titleColor;
                text-align: center;
                margin-top: 0;
                height: 43px;
                max-height: 43px;
                overflow: hidden;
                margin-bottom: 10px;
            }
            p {
                color: $contentColor;
                text-align: center;
                height: 50px;
                word-wrap: break-word;
                overflow: hidden;
            }
            .description_show {
                display: block;
            }
            .description_hide {
                display: none;
            }
            .product_three_links_container {
                @include flex(row);
                width: 250px;
                overflow: hidden;
            }
            .product_links_container {
                @include flex(row);
                width: 250px;
                flex-wrap: wrap;
            }
        }
    }
    .product_footer {
        @include flex(row, flex-end, flex-end);
        position: relative;
        right: 2px;
        bottom: 5px;
        width: 270px;
        height: 5px;
        .more_links_container {
            p {
                font-size: 16px;
                margin: 0;
                color: $titleColor;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.store_product_show_more {
    height: auto;
}

.store_product_show_less {
    height: 450px;
    overflow: hidden;
}