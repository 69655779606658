@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.admin_unlock_panel {
    position: relative;
    z-index: 1;
    text-align: left;
    width: 970px;
    max-width: 970px;
    .admin_unlock_header {
        background: url("../../Styles/Images/Panel/title.jpg");
        padding: 10px;
        color: $titleColor;
    }
    .admin_unlock_content {
        background: url("../../Styles/Images/Panel/middle.jpg");
        width: 100%;
        h4 {
            color: $contentColor;
        }
        .admin_unlock_form_container {
            @include flex(column);
            width: 100%;
            margin-top: 10px;
            .admin_unlock_err_container {
                background: $errorBackground;
                border: 1px solid $errorBorder;
                padding: 5px;
                border-radius: 6px;
                p {
                    color: $errorText;
                    margin: 0;
                }
            }
            div {
                position: relative;;
                width: 50%;
                margin-bottom: 10px;
                p {
                    color: $contentColor
                }
                input {
                    width: 100%;
                    height: 30px;
                    border-radius: 6px;
                    padding: 5px;
                }
                img {
                    position: absolute;
                    right: 25px;
                    width: 30px;
                    height: 30px;
                    margin-bottom: 13px;
                    z-index: 3;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .admin_unlock_btn_container {
                width: 50%;
                float: left;
                button {
                    float: right;
                    border: none;
                    width: 100px;
                    color: $contentColor;
                    background: $btnPrimaryBackground;
                    padding: 10px;
                    border-radius: 6px;
                    outline: none;
                    &:hover {
                        cursor: pointer;
                        background: $btnPrimaryHover;
                    }
                }
            }
        }
    }
}