@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.page_footer {
    @include flex(row);
    background-color: $blackTransparent;
    font-size: 16px;
    width: 100%;
    height: 25px;
    text-align: center;
    padding-top: 2px;
    color: #eee;
    margin-top: -25px;
    z-index: 10;
    #inner_footer_container {
        @include flex(row);
        a {
            color: $footerLink;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
        p {
            text-align: center;
            margin: 0 !important;
            color: $footerLink;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}

.page_footer_about {
    position: fixed !important;
    justify-content: space-between !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
}