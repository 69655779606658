@import "../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../Styles/variables.scss";

.admin_store_preview_model {
    @include flex(column, space-between);
    background: $blackTransparent;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .admin_store_sections_preview_container {
        max-width: 970px !important;
        margin-bottom: 30px;
        height: 231px;
        .wall_grass_top {
            max-width: 970px !important;
        }
        .wall {
            position: relative;
            padding-top: 0;
            margin-bottom: 40px;
            .admin_store_preview_header {
                position: relative;
                max-width: 970px !important;
                width: 970px;
                height: 78px;
                z-index: 2;
            }
            .wall_background {
                max-width: 970px !important;
                .wall_fade_in {
                    max-width: 970px !important;
                }
                .wall_content {
                    max-width: 970px !important;
                }
                .wall_fade_out {
                    max-width: 970px !important;
                }
                .wall_footer {
                    background: url("../../../../../../../../Styles/Images/Panel/bottom_none.jpg");
                    max-width: 970px !important;
                    height: 78px;
                }
            }
        }
    }
    .admin_close_model_btn {
        position: absolute;
        top: 5px;
        right: 5px;
        color: $black;
        background: $white;
        &:hover {
            cursor: pointer;
            background: $offWhite;
        }
        &:focus {
            outline: none;
        }
    }
}