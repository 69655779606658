@import "../../../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../../../Styles/variables.scss";

.admin_edit_link {
    @include flex(column, center, flex-start);
    width: 100%;
    h4 {
        color: $contentColor;
    }
    .admin_store_form_container {
        width: 50%;
    }
    .admin_store_preview_container {
        @include flex(column);
        width: 50%;
        height: 200px;
        img {
            width: 50px;
            height: 50px;
            background-position: center !important;
            background-size: 100% 100% !important;
        }
    }
}