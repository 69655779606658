@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.store_partners {
    width: 970px;
    margin-bottom: 20px;
    .partners_header {
        background: url("../../../../Styles/Images/Store/Header_Partners.jpg");
        width: 970px;
        height: 78px;
    }
    .wall_content {
        padding: 10px;
    }
    .partners_container {
        @include flex(row);
        width: 950px;
        flex-wrap: wrap;
    }
}