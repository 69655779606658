@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.wallpapers_block {
    width: 100%;
    #wallpapers {
        width: 100%;
        margin-bottom: 40px;
        .wall_header {
            background: url("../../../../Styles/Images/Media/wallpapers.png");
            width: 100%;
            height: 80px;
        }
        .wall_content_wallpapers {
            width: 100%;
            padding: 0;
            padding-left: 2px;
            padding-right: 2px;
            .wall_separator_d {
                background: url("../../../../Styles/Images/Media/separator-desktop.png");
                width: 100%;
                height: 52px;
            }
            .wall_separator_m {
                background: url("../../../../Styles/Images/Media/separator-mobile.png");
                width: 100%;
                height: 52px;
            }
            .wallpaper_list {
                @include flex(row);
                flex-wrap: wrap;
                -webkit-box-shadow: inset 0 0 30px 0 #0a0a0a;
                -moz-box-shadow: inset 0 0 30px 0 #0a0a0a;
                box-shadow: inset 0 0 30px 0 #0a0a0a;
                width: 100%;
                padding-top: 22px;
                text-align: center;
                padding-bottom: 22px;
            }
        }
        .wall_footer_wallpapers {
            background: url("../../../../Styles/Images/Media/gallery_footer.jpg");
            margin-top: 0;
            height: 41px;
        }
    }
}