@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_media_edit_gallery_panel {
    @include flex(column, center, flex-start);
    width: 113%;
    margin-top: -30px;
    margin-left: -20px;
    h2 {
        color: $contentColor;
    }
    div {
        @include flex(row, flex-start);
        width: 100%;
        input {
            width: 80%;
            margin-right: 10px;
            height: 30px;
            border-radius: 6px;
            padding: 10px;
        }
    }
    button {
        background: $white;
        padding: 8px 15px;
        border: none;
        border-radius: 6px;
        &:hover {
            cursor: pointer;
            background: $offWhite;
        }
        &:focus {
            outline: none;
        }
    }
}

#admin_media_sync_album_btn {
    position: relative;
    font-size: 13px;
    transition: all 1s;
    &::before {
        top: 30% !important;
        left: 45% !important;
    }
}