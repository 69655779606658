@import "../../../Styles/mixins.scss";
@import "../../../Styles/variables.scss";

.news_block_shadow {
    position: relative;
    width: 300px;
    height: 328px;
    display: inline-block;
    margin-top: 30px;
    .news_block_grass {
        position: relative;
        height: 10px;
        width: 300px;
        background-image: url("../../../Styles/Images/Wall/news_block_grass.png");
    }
    .news_block {
        background: url("../../../Styles/Images/Home/news_block.jpg");
        display: inline-block;
        position: relative;
        width: 300px;
        height: 318px;
        overflow: hidden;
        padding: 10px;
        padding-top: 0;
        .news_block_image_container {
            position: absolute;
            top: 14px;
            left: 18px;
            img {
                width: 265px;
                height: 183px;
                background-position: center !important;
                background-size: 100% 100% !important;
            }
            .fading_image {
                opacity: .5;
                transition: opacity .5s;
                -webkit-transition: opacity .5s;
                &:hover {
                    opacity: 1;
                }
            }
        }
        h4 {
            color: $titleColor;
            text-align: center;
            margin-bottom: 2px;
            margin-top: 205px;
            font-size: 18px;
        }
        p {
            color: $contentColor;
            line-height: 120%;
            margin-bottom: 3px;
            font-size: 15px;
            text-align: justify;
            margin: 0;
        }
        .read_more {
            @include flex(row);
            position: absolute;
            font-size: 16px;
            right: 10px;
            bottom: 4px;
            p {
                color: $titleColor;
            }
        }
        .news_date {
            position: absolute;
            color: $titleColor;
            font-size: 16px;
            left: 10px;
            bottom: 4px;
        }
    }
}