@import "../../../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../../../Styles/variables.scss";

.admin_single_store_section_container {
    @include flex(row, space-between);
    width: 100%;
    border-bottom: 1px solid $black;
    padding: 10px;
    p {
        margin-bottom: 0;
    }
    button {
        margin-right: 10px;
        background: $white;
        border: 1px solid black;
        border-radius: 6px;
        padding: 2px 5px;
        width: 50px;
        height: 20px;
        &:hover {
            cursor: pointer;
            background: $offWhite;
        }
        &:focus {
            outline: none;
        }
    }
    .admin_store_section_categories_btn {
        width: 100px !important;
    }
    .admin_store_section_delete_btn {
        position: relative;
        font-size: 13px;
        transition: all 1s;
    }
    .admin_store_section_published_btn {
        position: relative;
        width: 100px !important;
        font-size: 13px;
        transition: all 1s;
        background: $lightBlue !important;
        &:hover {
            background: $lightBlueHover !important;
        }
    }
    .admin_store_section_unpublished_btn {
        position: relative;
        width: 100px !important;
        font-size: 13px;
        transition: all 1s;
        background: $lightOrange !important;
        &:hover {
            background: $lightOrangeHover !important;
        }
    }
}