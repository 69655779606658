@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.admin_user_container {
    @include flex(row, space-between);
    width: 100%;
    margin-bottom: 20px;
    overflow: auto;
    h4 {
        color: $contentColor;
    }
    .admin_user_role_container {
        width: 70%;
        h4 {
            margin-right: 10px;
            width: 50%;
        }
        div {
            @include flex(row, space-between);
            width: 100%;
            .admin_user_btn {
                    margin-right: 10px;
                    border: none;
                    width: 100px;
                    color: $contentColor;
                    background: $btnPrimaryBackground;
                    padding: 10px;
                    border-radius: 6px;
                    outline: none;
                    &:hover {
                        cursor: pointer;
                        background: $btnPrimaryHover;
                    }
            }
            div {
                @include flex(row, flex-end);
            }
            select {
                margin-right: 10px;
                height: 30px;
            }
            .admin_user_locked_container {
                @include flex(row, flex-start);
                margin-left: 10px;
                p {
                    color: $contentColor;
                    font-size: 12px;
                    margin-right: 10px;
                    width: 50px;
                }
                button {
                    border: none;
                    width: 100px;
                    color: $contentColor;
                    background: $btnPrimaryBackground;
                    padding: 10px;
                    border-radius: 6px;
                    outline: none;
                    &:hover {
                        cursor: pointer;
                        background: $btnPrimaryHover;
                    }
                }
            }
        }
        .admin_user_editing_role_change_cancel_container {
            @include flex(row, flex-start);
            width: 50%;
            button {
                width: 100px;
            }
        }
        .admin_user_editing_user_password_container {
            .password_input {
                position: relative;
                @include flex(row);
                width: 100%;
                input {
                    width: 75%;
                    height: 30px;
                    border-radius: 6px;
                }
                img {
                    position: absolute;
                    right: 45px;
                    width: 30px;
                    height: 30px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

#change_role_btn {
    font-size: 13px;
    transition: all 1s;
    position: relative;    
}

#unlock_btn {
    font-size: 13px;
    transition: all 1s;
    position: relative;
}

.admin_delete_user_btn {
    font-size: 13px;
    transition: all 1s;
    position: relative;
}

.admin_change_password_btn {
    font-size: 13px;
    transition: all 1s;
    position: relative;
}