@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');
@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.store_category {
    margin-right: 30px;
    &:last-child {
        margin-right: 0;
    }
    p {
        color: $contentColor;
        font-size: 25px;
        font-weight: bold;
        font-family: 'Merriweather', 'Open Sans', sans-serif;

        &:hover {
            cursor: pointer;
            color: $storeCategoryActive;
        }
    }
}

.active_category {
    p {
        color: $storeCategoryActive;
        text-decoration: underline;
    }
}