@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.store_section {
    width: 970px;
    .store_section_header {
        @include flex(row, flex-end);
        width: 970px;
        height: 78px;
        .back_to_top_arrow {
            background: url("../../../../Styles/Images/up_arrow.png");
            width: 25px;
            height: 25px;
            margin-right: 10px;
            margin-bottom: 12px;
            opacity: 0.7;
            transition: opacity 0.2s;
            &:hover {
                cursor: pointer;
                opacity: 1;
            }
        }
    }
    .wall_content {
        padding: 10px;
        .section_content {
            @include flex(column);
            width: 950px;
            margin-bottom: 20px;
            .store_categories_search_container {
                @include flex(column);
                width: 950px;
                .store_categories_container {
                    @include flex(row);
                    flex-wrap: wrap;
                    width: 950px;
                }
                .store_search_container {
                    @include flex(row, flex-end);
                    width: 950px;
                    margin-bottom: 10px;
                    input {
                        height: 30px;
                        border-radius: 6px;
                        width: 200px;
                        background: transparent;
                        border: 2px solid $storeInputBorder;
                        color: $storeInputColor;
                        &:focus {
                            outline: none;
                            box-shadow: 0px 0px 0px 1px $white;
                        }
                    }
                    .search_box {
                        position: relative;
                        width: 10px;
                        margin-left: 8px;
                        margin-bottom: 5px;
                        transform: rotate(125deg);
                        &:hover {
                            cursor: pointer;
                        }
                        .search {
                            width: 10px;
                            height: 10px;
                            border: 3px solid $searchIconColor;
                            border-radius: 10px;
                        }
                        .cabe {
                            width: 10px;
                            display: block;
                            border: 2px solid $searchIconColor;
                            color: #f6f6f6;
                            font-weight: bold;
                            text-shadow: 2px 0px $searchIconColor;
                            position: absolute;
                            top: 3px;
                            left: 7px;
                            font-size: 16px;
                            border-radius: 10px;
                        }
                    }
                }
            }
            .store_products_area {
                @include flex(column, center, flex-end);
                width: 950px;
                .mini_page_wrapper {
                    background: url("../../../../Styles/Images/pagination/mini_page_dropshadow.png");
                    padding: 6px 6px 6px 5px;
                    height: 36px;
                    width: 175px;
                    margin-bottom: 10px;
                    .mini_page_selector {
                        display: block;
                        float: left;
                        height: 24px;
                        .mini_page {
                            display: block;
                            background: url("../../../../Styles/Images/pagination/mini_page_buttons.png");
                            height: 24px;
                            float: left;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                        .mini_first_btn {
                            width: 30px;
                            background-position: 0 0!important;
                        }
                        .enabled.mini_first_btn {
                            background-position: 186px 0!important;
                            &:hover {
                                background-position: 93px 0!important;
                            }
                        }
                        .mini_previous_btn {
                            width: 16px;
                            background-position: -30px 0!important;
                        }
                        .enabled.mini_previous_btn {
                            background-position: 156px 0!important;
                            &:hover {
                                background-position: 62px 0!important;
                            }
                        }
                        .mini_page_content {
                            display: block;
                            background: url("../../../../Styles/Images/pagination/mini_page_bar.gif");
                            overflow: hidden;
                            height: 24px;
                            width: 72px;
                            float: left;
                            font-size: 12px;
                            color: #f6ffe3;
                            padding-top: 3px;
                            .mini_page_input_container {
                                @include flex(row);
                                height: 24px;
                                width: 72px;
                                p {
                                    text-align: center;
                                }
                                .store_section_page_number_input {
                                    position: relative;
                                    bottom: 3px;
                                    width: 35px;
                                    height: 18px;
                                    margin-right: 5px;
                                    background: transparent;
                                    border: 2px solid $storeInputBorder;
                                    color: $storeInputColor;
                                    border-radius: 3px;
                                    text-align: right;
                                    padding: 0 4px;
                                    &:focus {
                                        outline: none;
                                        box-shadow: 0px 0px 0px 1px $white;
                                    }
                                    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                                        -webkit-appearance: none;
                                        margin: 0;
                                    }
                                }
                                input[type=number] {
                                    -moz-appearance: textfield;
                                }
                                .store_section_pages_slash {
                                    margin: 0;
                                    margin-right: 5px;
                                    font-size: 18px;
                                    margin-bottom: 5px;
                                }
                                .store_section_pages_number {
                                    margin: 0;
                                    font-size: 15px;
                                    margin-bottom: 5px;
                                }
                            }
                        }
                        .mini_next_btn {
                            width: 16px;
                            background-position: -46px 0!important;
                        }
                        .enabled.mini_next_btn {
                            background-position: 141px 0!important;
                            &:hover {
                                background-position: 48px 0!important;
                            }
                        }
                        .mini_last_btn {
                            width: 30px;
                            background-position: -62px 0!important;
                        }
                        .enabled.mini_last_btn {
                            background-position: 124px 0!important;
                            &:hover {
                                background-position: 31px 0!important;
                            }
                        }
                    }
                }
                .store_products_container {
                    @include flex(row, center, flex-start);
                    width: 950px;
                    min-height: 490px;
                    flex-wrap: wrap;
                    margin-bottom: 10px;
                }
            }
        }
    }
}