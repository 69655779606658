@import "../../../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../../../Styles/variables.scss";

.admin_add_store_section_categories {
    @include flex(column, center, flex-start);
    width: 100%;
    .admin_add_store_section_categories_input_container {
        width: 100%;
        margin-bottom: 10px;
        p {
            color: $contentColor;
        }
        input {
            width: 100%;
            height: 30px;
            border-radius: 6px;
            padding: 5px;
        }
    }
    .admin_add_store_section_categories_btn_container {
        @include flex(row, space-between);
        width: 100%;
        margin-bottom: 20px;
        button {
            background: $white;
            border: none;
            width: 85px;
            height: 30px;
            border-radius: 6px;
            &:hover {
                cursor: pointer;
                background: $offWhite;
            }
            &:focus {
                outline: none;
            }
        }
        #admin_add_store_section_categories_add_btn {
            position: relative;
            font-size: 13px;
            transition: all 1s;
        }
    }
}