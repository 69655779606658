@import "../../../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../../../Styles/variables.scss";

.admin_tile_preview {
    @include flex(column);
    background: $blackTransparent;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    img {
        background-position: center !important;
        background-size:  100% 100% !important;
        border: 2px solid $brownBorder;
    }
    .admin_tile_preview_one_img_one_by_two {
        img {
            width: 466px;
            height: 516px;
        }
    }
    .admin_tile_preview_two_img_one_by_two {
        img {
            width: 466px;
            height: 251px;
        }
    }
    .admin_tile_preview_two_img_two_by_one {
        img {
            width: 466px;
            height: 251px;
        }
    }
    .admin_tile_preview_one_img_two_by_one {
        img {
            width: 946px;
            height: 251px;
        }
    }
    .admin_tile_preview_one_img_two_by_two {
        img {
            width: 946px;
            height: 516px;
        }
    }
}