@import "../../../../../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../../../../../Styles/variables.scss";

.admin_product {
    @include flex(row, space-between);
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid $black;
    .admin_product_editing_featured_container {
        @include flex(row);
        select {
            margin-right: 5px;
            padding: 1px;
            border-radius: 6px;
            &:hover {
                cursor: pointer;
            }
        }
        .editing_featured_x_btn {
            width: 20px;
        }
    }
    .admin_product_editing_category_container {
        @include flex(row);
        select {
            margin-right: 5px;
            padding: 1px;
            border-radius: 6px;
            &:hover {
                cursor: pointer;
            }
        }
        .editing_category_x_btn {
            width: 20px;
        }
    }
    .admin_product_btn_container {
        flex-wrap: wrap;
        height: 75px;
        button {
            background: $white;
            margin-bottom: 5px;
            border: 1px solid black;
            border-radius: 6px;
            padding: 2px 5px;
            width: 50px;
            height: 20px;
            &:hover {
                cursor: pointer;
                background: $offWhite;
            }
            &:focus {
                outline: none;
            }
        }
        .admin_product_delete_btn {
            position: relative;
            font-size: 13px;
            transition: all 1s;
        }
        .admin_product_published_btn {
            position: relative;
            width: 100px !important;
            font-size: 13px;
            transition: all 1s;
            background: $lightBlue !important;
            &:hover {
                background: $lightBlueHover !important;
            }
        }
        .admin_product_unpublished_btn {
            position: relative;
            width: 100px !important;
            font-size: 13px;
            transition: all 1s;
            background: $lightOrange !important;
            &:hover {
                background: $lightOrangeHover !important;
            }
        }
    }
}