@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
@import "./Styles/mixins.scss";
@import "./Styles/variables.scss";

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: "Open Sans", sans-serif;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.42857143;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	background: url("./Styles/Images/background.jpg");
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-color: #0A0A0A;
	text-align: center;
	font-family: "Open Sans", sans-serif;
	min-width: 970px;
	scroll-behavior: smooth;
}

html, body {
	height: 100%;
	width: 100%;
}

html {
    -webkit-tap-highlight-color: transparent;
}

html {
	font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

html {
	display: block;
}

* {
	-webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
	box-sizing: border-box !important;
	margin: 0;
}

*::before, *:after {
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

p {
	margin: 0 0 10px;
}

a {
	color: #337ab7;
	text-decoration: none;
	background-color: transparent;
}

b, strong {
	font-weight: 700;
}

ul ul, ul ol, ol ul, ol ol {
	margin-bottom: 0;
}

ul, ol {
	margin-top: 0;
	margin-bottom: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    &::before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        top: 25%;
        left: 45%;
        border-radius: 50%;
        border: solid 3px $spinnerColor;
        border-bottom-color: $spinnerBottomColor;
        animation: .8s linear infinite spin;
        transform: translate(-50%, -50%);
        will-change: transform;
    }
}

#root {
	position: relative;
	height: 100%;
}

.main_wrapper {
	@include flex(column);
	width: 100%;
	overflow-x: hidden;
}