@import "../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../Styles/variables.scss";

.admin_store_edit_partner {
    width: 100%;
    .admin_store_edit_partner_form_preview_container {
        @include flex(row, space-between, flex-start);
        width: 100%;
        .admin_store_edit_partner_link_input_err {
            border: 1px solid $errorText;
            &:focus {
                outline-color: $errorText
            }
        }
        .admin_store_edit_partner_link_input {
            border-width: 2px;
            border-style: inset;
            border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
            border-image: initial;
            &:focus {
                outline-color: $black
            }
        }
        .admin_store_edit_partner_preview_container {
            @include flex(column, flex-start, flex-end);
            width: 50%;
            margin-top: 30px;
            .admin_store_edit_partner_preview {
                @include flex(column, flex-start);
                width: 225px;
                height: 200px;
                overflow: hidden;
                padding: 10px;
                .admin_store_edit_partner_preview_img_container {
                    width: 100px;
                    height: 100px;
                    img {
                        width: 100px;
                        height: 100px;
                        background-size: cover;
                        background-repeat: no-repeat;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
                h4 {
                    color: $titleColor;
                    text-align: center;
                    overflow: hidden;
                    word-wrap: break-word;
                    width: 225px;
                    max-height: 75px;
                }
                p {
                    color: $contentColor;
                    width: 225px;
                    height: 60px;
                    text-align: center;
                    word-wrap: break-word;
                    overflow: hidden;
                    &:hover {
                        cursor: pointer;
                    }
                }
                .light_background {
                    background: $white;
                }
                .dark_background {
                    background: $darkGrey;
                }
            }
        }
    }
    .admin_store_btn_container {
        @include flex(row, space-between);
        width: 100%;
        button {
            background: $white;
            border: none;
            width: 85px;
            height: 30px;
            border-radius: 6px;
            &:hover {
                cursor: pointer;
                background-color: $offWhite;
            }
            &:focus {
                outline: none;
            }
        }
        #admin_store_edit_partner_update_btn {
            position: relative;
            font-size: 13px;
            transition: all 1s;
        }
    }
}