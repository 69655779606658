@import "../../../../../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../../../../../Styles/variables.scss";

.admin_all_tiles_container {
    margin-bottom: 10px;
    img {
        background-position: center !important;
        background-size:  100% 100% !important;
        border: 2px solid $brownBorder;
    }
    .admin_one_img_one_by_two {
        width: 470px;
        height: 516px;
        img {
            width: 470px;
            height: 516px;
        }
    }
    .admin_two_img_one_by_two {
        @include flex(column, space-between);
        width: 470px;
        height: 516px;
        img {
            width: 470px;
            height: 253px;
        }
    }
    .admin_two_img_two_by_one {
        @include flex(row, space-between);
        width: 950px;
        height: 253px;
        img {
            width: 470px;
            height: 253px;
        }
    }
    .admin_one_img_two_by_one {
        img {
            width: 950px;
            height: 253px;
        }
    }
    .admin_one_img_two_by_two {
        img {
            width: 950px;
            height: 516px;
        }
    }
}