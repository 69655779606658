@import "../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../Styles/variables.scss";

.admin_store_add_home_drop_down_menu {
    width: 100%;
    .admin_store_btn_container {
        @include flex(row, space-between);
        width: 100%;
        button {
            background: $white;
            border: none;
            width: 85px;
            height: 30px;
            border-radius: 6px;
            &:hover {
                cursor: pointer;
                background-color: $offWhite;
            }
            &:focus {
                outline: none;
            }
        }
    }
}