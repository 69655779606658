@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.admin_home_container {
    width: 100%;
    .frontpage_video_info_container {
        @include flex(column, center, flex-start);
        color: $contentColor;
        width: 100%;
        margin-bottom: 20px;
        .frontpage_input_container {
            width: 100%;
            margin-bottom: 20px;
            input {
                width: 100%;
                height: 30px;
                border-radius: 6px;
                padding: 10px;
                color: $grey;
            }
        }
        .frontpage_description_container {
            width: 100%;
            margin-bottom: 10px;
            textarea {
                width: 100%;
                border-radius: 6px;
                padding: 10px;
                color: $grey;
            }
        }
        .frontpage_btn_container {
            width: 100%;
            float: left;
            button {
                float: right;
                border: none;
                width: 100px;
                color: $contentColor;
                background: $btnPrimaryBackground;
                padding: 10px;
                border-radius: 6px;
                outline: none;
                &:hover {
                    cursor: pointer;
                    background: $btnPrimaryHover;
                }
            }
        }
    }
    .admin_home_dedicated_server_info_container {
        color: $contentColor;
        .upload_file_container {
            @include flex(row, flex-start);
            background: $white;
            width: 100%;
            padding: 5px;
            border-radius: 6px;
            margin-bottom: 10px;
            p {
                color: $grey;
                margin: 0;
            }
            input {
                &:focus {
                    outline: none;
                }
            }
            .dedicated_server_upload_btn {
                width: 80px;
                &::-webkit-file-upload-button {
                    visibility: hidden;
                }
                &::before {
                    content: 'Browse...';
                    display: inline-block;
                    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
                    color: $black;
                    border: 1px solid $black;
                    padding: 5px 8px;
                    outline: none;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .dedicated_server_upload_btn_container {
            width: 100%;
            float: left;
            button {
                float: right;
                border: none;
                width: 100px;
                color: $contentColor;
                background: $btnPrimaryBackground;
                padding: 10px;
                border-radius: 6px;
                outline: none;
                &:hover {
                    cursor: pointer;
                    background: $btnPrimaryHover;
                }
            }
        }
    }
}

#update_youtube_info_btn {
    font-size: 13px;
    transition: all 1s;
    position: relative;
}

#change_pc_dedicated_server_btn {
    font-size: 13px;
    transition: all 1s;
    position: relative;
}

#change_mobile_dedicated_server_btn {
    font-size: 13px;
    transition: all 1s;
    position: relative;
}