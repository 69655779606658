@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.admin_change_password_panel {
    width: 100%;
    h3 {
        color: $contentColor;
        margin-top: 0;
    }
    .change_password_form_container {
        width: 100%;
        .change_password_input_container {
            @include flex(row, space-between);
            position: relative;
            width: 100%;
            margin-bottom: 10px;
            p {
                margin: 0;
                color: $contentColor;
            }
            input {
                width: 75%;
                height: 30px;
                border-radius: 6px;
            }
            img {
                position: absolute;
                right: 35px;
                width: 30px;
                height: 30px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .change_password_btn_container {
            width: 100%;
            float: left;
            button {
                float: right;
                border: none;
                color: $contentColor;
                width: 130px;
                background: $btnPrimaryBackground;
                padding: 10px;
                border-radius: 6px;
                outline: none;
                &:hover {
                    cursor: pointer;
                    background: $btnPrimaryHover;
                }
            }
        }
    }
}

#admin_change_password_btn {
    font-size: 13px;
    transition: all 1s;
    position: relative;
}