@import "../../../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../../../Styles/variables.scss";

.admin_container {
    @include flex(row, space-between);
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid $black;
    .admin_container_btn_container {
        button {
            background: $white;
            margin-right: 5px;
            border: 1px solid black;
            border-radius: 6px;
            padding: 2px 5px;
            width: 50px;
            height: 20px;
            &:hover {
                cursor: pointer;
                background: $offWhite;
            }
            &:focus {
                outline: none;
            }
        }
    }
}