@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.admin_store {
    @include flex (column, center, flex-start);
    width: 100%;
    margin-top: -30px;
    margin-left: -5px;
    .admin_store_nav {
        @include flex(row);
        width: 100%;
        border: none;
        margin-bottom: 10px;
        border-bottom: 1px solid $white;
        padding-bottom: 5px;
        .admin_store_nav_btn {
            list-style-type: none;
            border-radius: 6px;
            width: 150px;
            text-align: center;
            padding: 10px;
            margin-right: 10px;
            &:hover {
                cursor: pointer;
                background: $btnPrimaryBackground;
            }
        }
        .admin_store_partners_btn {
            margin-right: 0;
        }
        .admin_store_nav_active {
            background: $btnPrimaryBackground;
        }
    }
}

.admin_store_btn_container {
    @include flex(row, space-between);
    width: 100%;
    button {
        background: $white;
        border: none;
        width: 85px;
        height: 30px;
        border-radius: 6px;
        &:hover {
            cursor: pointer;
            background-color: $offWhite;
        }
        &:focus {
            outline: none;
        }
    }
    .admin_store_preview_add_container {
        :first-child {
            margin-right: 5px;
        }
    }
    .admin_store_preview_update_container {
        :first-child {
            margin-right: 5px;
        }
    }
}

.admin_store_form_preview_container {
    @include flex(row, space-between, flex-start);
    width: 100%;
}

.admin_store_form_container {
    width: 100%;
    .admin_store_input_container {
        width: 100%;
        margin-bottom: 10px;
        p {
            color: $contentColor;
        }
        input {
            width: 100%;
            height: 30px;
            border-radius: 6px;
            padding: 5px;
        }
        select {
            width: 50%;
            height: 30px;
            padding: 5px;
            border-radius: 6px;
        }
        textarea {
            width: 100%;
            padding: 5px;
            border-radius: 6px;
        }
        .admin_store_file_upload_container {
            @include flex(row, flex-start);
            background: $white;
            width: 100%;
            padding: 5px;
            border-radius: 6px;
            margin-bottom: 10px;
            p {
                color: $grey;
                margin: 0;
            }
            input {
                width: 80px;
                padding: 0;
                border-radius: 0;
                &::-webkit-file-upload-button {
                    visibility: hidden;
                }
                &::-moz-file-upload-button {
                    visibility: hidden;
                }
                &::before {
                    content: 'Browse...';
                    display: inline-block;
                    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
                    color: $black;
                    border: 1px solid $black;
                    padding: 5px 8px;
                    outline: none;
                }
                &:hover {
                    cursor: pointer;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        .admin_store_order_change_container {
            @include flex(row, flex-start, center);
            width: 100%;
            input {
                width: 50%;
            }
            button {
                background: $white;
                border: none;
                width: 100px;
                height: 30px;
                border-radius: 6px;
                margin-left: 5px;
                &:hover {
                    cursor: pointer;
                    background-color: $offWhite;
                }
                &:focus {
                    outline: none;
                }
            }
            .admin_store_order_reset_cancel_btn {
                width: 25px;
                margin-right: 5px;
                margin-left: 0;
            }
        }
    }
}

.admin_store_previous_next_container {
    @include flex(row, space-between);
    width: 100%;
    margin-bottom: 10px;
    button {
        background: $white;
        border: none;
        border-radius: 15px;
        padding: 10px 15px;
        font-size: 120%;
        color: $green;
        &:focus {
            outline: none;
        }
        &:disabled {
            color: $lightGrey;
        }
        &:hover {
            cursor: pointer;
            background: $offWhite;
        }
    }
}

.admin_store_page_info_container {
    @include flex(row, space-between);
    width: 100%;
    background: $whiteTransparent;
    border: 1px solid $white;
    border-radius: 6px;
    padding: 5px;
    margin-bottom: 20px;
    h4 {
        margin: 0;
        color: $grey;
    }
    button {
        background: $white;
        border: none;
        padding: 8px 15px;
        border-radius: 3px;
        margin-top: 5px;
        &:focus {
            outline: none;
        }
        &:hover {
            cursor: pointer;
            background: $offWhite;
        }
    }
}

.admin_store_list_items_container {
    background: $white;
    width: 100%;
    border-radius: 6px;
    margin-bottom: 20px;
}

.admin_store_list_item {
    @include flex(row, space-between);
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid $black;
    p {
        margin-bottom: 0;
    }
    .admin_store_list_item_btn_container {
        @include flex(row, flex-end);
        width: 50%;
        button {
            background: $white;
            margin-right: 5px;
            border: 1px solid black;
            border-radius: 6px;
            padding: 2px 5px;
            width: 50px;
            height: 20px;
            &:hover {
                cursor: pointer;
                background: $offWhite;
            }
            &:focus {
                outline: none;
            }
        }
    }
}

.admin_store_spinner_container {
    position: relative;
    font-size: 13px;
    transition: all 1s;
}

.admin_store_standard_btn {
    background: $white;
    border: none;
    width: 85px;
    height: 30px;
    border-radius: 6px;
    &:hover {
        cursor: pointer;
        background-color: $offWhite;
    }
    &:focus {
        outline: none;
    }
}

.admin_store_sort_by_container {
    @include flex(row, flex-end);
    width: 100%;
    margin-bottom: 10px;
    p {
        color: $contentColor;
        margin: 0 5px 0 0;
    }
    select {
        width: 20%;
        height: 30px;
        padding: 5px;
        border-radius: 6px;
    }
}