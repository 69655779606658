@import "../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../Styles/variables.scss";

.admin_edit_container {
    width: 100%;
    h4 {
        color: $contentColor;
    }
    .admin_edit_container_warning_container {
        width: 100%;
        p {
            color: $contentColor;
        }
    }
}