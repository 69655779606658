@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.pc_wallpaper_block {
    -webkit-box-shadow: 0 0 20px 0 #0a0a0a;
    -moz-box-shadow: 0 0 20px 0 #0a0a0a;
    box-shadow: 0 0 20px 0 #0a0a0a;
    position: relative;
    display: inline-block;
    margin: 10px 40px 0;
    width: 320px;
    height: 180px;
}