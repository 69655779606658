@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_post_panel {
    border-bottom: 1px solid $grey;
    padding: 10px;
    width: 100%;
    .admin_post_content {
        @include flex(row, space-between);
        width: 100%;
        .admin_post_title_author {
            @include flex(row, flex-start);
            p {
                margin: 0;
                word-break: break-all;
            }
            .admin_post_title {
                height: 25px;
                overflow: auto;
                width: 200px;
            }
            .admin_post_long {
                overflow: hidden !important;
                width: 200px !important;
            }
            .admin_post_short {
                overflow: auto !important;
                width: auto !important;
            }
            .admin_post_author {
                font-size: 70%;
                color: $postAuthorPurple;
                margin-left: 5px;
            }
            
        }
        .admin_post_info_container {
            @include flex(row, flex-start);
            p {
                margin: 0;
                padding-bottom: 10px;
                font-size: 80%;
                margin-right: 10px;
            }
            button {
                margin-right: 10px;
                background: $white;
                border: 1px solid $black;
                border-radius: 6px;
                padding: 2px 5px;
                width: 50px;
                height: 20px;
                &:hover {
                    cursor: pointer;
                    background: $offWhite;
                }
                &:focus {
                    outline: none;
                }
            }
            .admin_post_published_btn {
                position: relative;
                font-size: 13px;
                transition: all 1s;
                background: $lightBlue;
                width: 100px;
                &:hover {
                    background: $lightBlueHover;
                }
            }
            .admin_post_not_published_btn {
                position: relative;
                font-size: 13px;
                transition: all 1s;
                background: $lightOrange;
                width: 100px;
                &:hover {
                    background: $lightOrangeHover;
                }
            }
        }
    }
}

.admin_delete_post_btn {
    position: relative;
    font-size: 13px;
    transition: all 1s;
    &::before {
        top: 20% !important;
        left: 40% !important;
    }
}