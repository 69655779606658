@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.galleries_block {
    width: 100%;
    #media_galleries {
        width: 100%;
        .wall_header {
            height: 78px;
            background-image: url("../../../../Styles/Images/Media/galleries_title.jpg");
        }
        .wall_content {
            padding: 0;
            padding-left: 2px;
            padding-right: 2px;
            transition: max-height .5s;
            overflow: hidden;
            .gallery {
                @include flex(row);
                flex-wrap: wrap;
                -webkit-box-shadow: inset 0 0 30px 0 #0a0a0a;
                -moz-box-shadow: inset 0 0 30px 0 #0a0a0a;
                box-shadow: inset 0 0 30px 0 #0a0a0a;
                text-align: center;
            }
        }
        .wall_footer {
            position: relative;
            background-image: url("../../../../Styles/Images/Media/gallery_footer.jpg");
            margin-top: 0;
            height: 41px;
            width: 970px;
            .wall_footer_content {
                display: inline-block;
                position: absolute;
                width: 970px;
                text-align: center;
                bottom: 0;
                color: $titleColor;
                h3 {
                    transition: opacity .4s;
                    margin-bottom: 5px;
                }
                .gallery_expand_collapse_button {
                    @include flex(row, flex-start);
                    position: absolute;
                    right: 20px;
                    bottom: 8px;
                    font-size: 18px;
                    color: $titleColor;
                    img {
                        margin-right: 5px;
                    }
                    p {
                        margin: 0;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}