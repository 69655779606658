@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.partner {
    @include flex(column, flex-start);
    width: 225px;
    height: 200px;
    overflow: hidden;
    h3 {
        color: $titleColor;
        width: 225px;
        text-align: center;
        max-height: 75px;
        height: 75px;
        overflow: hidden;
        word-wrap: break-word;
        margin-top: 10px;
    }
    p {
        color: $contentColor;
        width: 180px;
        height: 120px;
        word-wrap: break-word;
        text-align: center;
        &:hover {
            cursor: pointer;
        }
    }
    .partner_img_container {
        width: 100px;
        height: 100px;
        img {
            width: 100px;
            height: 100px;
            background-position: center !important;
            background-size:  100% 100% !important;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .partner_light {
        background: $white;
    }
    .partner_dark {
        background: $darkGrey;
    }
}