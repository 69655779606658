@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.music_block {
    width: 100%;
    #music_gallery {
        width: 100%;
        .wall_header {
            background-image: url("../../../../Styles/Images/Media/music_title.jpg");
            height: 78px;
        }
        .wall_content {
            padding: 0;
            padding-left: 2px;
            padding-right: 2px;
            .album_list {
                -webkit-box-shadow: inset 0 0 30px 0 #0a0a0a;
                -moz-box-shadow: inset 0 0 30px 0 #0a0a0a;
                box-shadow: inset 0 0 30px 0 #0a0a0a;
                width: 100%;
                height: 200px;
                padding-top: 22px;
                text-align: center;
                .album_block {
                    -webkit-box-shadow: 0 0 20px 0 #0a0a0a;
                    -moz-box-shadow: 0 0 20px 0 #0a0a0a;
                    box-shadow: 0 0 20px 0 #0a0a0a;
                    position: relative;
                    display: inline-block;
                    margin: 0 40px;
                    width: 206px;
                    height: 156px;
                    &:hover {
                        .album_options {
                            height: 30px;
                        }
                    }
                    img {
                        transition: opacity .4s;
                        opacity: .7;
                        &:hover {
                            opacity: 1;
                        }
                    }
                    .album_options {
                        position: absolute;
                        background-color: rgba(10,10,10,.5);
                        overflow: hidden;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 0;
                        transition: height .4s;
                        font-weight: 700;
                        font-size: 20px;
                        &:hover {
                            cursor: pointer;
                        }
                        .option_buy {
                            float: left;
                            display: block;
                            text-align: center;
                            color: $titleColor;
                            width: 50%;
                        }
                        .option_listen {
                            float: left;
                            display: block;
                            text-align: center;
                            width: 50%;
                            color: #d6ffe4;
                            border: none;
                            background: none;
                            border-left: #0a0a0a 1px solid;
                        }
                    }
                }
            }
            .album_player_wrapper {
                width: 100%;
                max-height: 0;
                height: 500px;
                overflow: hidden;
                transition: all .4s;
            }
        }
        .wall_footer {
            background-image: url("../../../../Styles/Images/Media/gallery_footer.jpg");
            width: 970px;
            height: 41px;
            margin-top: 0;
            .wall_footer_content {
                display: inline-block;
                position: absolute;
                width: 970px;
                text-align: center;
                bottom: 0;
                color: $titleColor;
                h3 {
                    transition: opacity .4s;
                    margin-bottom: 5px;
                    opacity: 0;
                }
                .collapse_album_player_btn {
                    @include flex(row, flex-start);
                    position: absolute;
                    right: 20px;
                    bottom: 8px;
                    font-size: 18px;
                    color: $titleColor;
                    &:hover {
                        cursor: pointer;
                    }
                    img {
                        margin-right: 5px;
                    }
                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
}