@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_view_galleries_panel {
    @include flex(column, center, flex-start);
    width: 113%;
    margin-top: -30px;
    margin-left: -20px;
    header {
        color: $contentColor;
        width: 100%;
    }
    .admin_media_content {
        width: 100%;
        .admin_media_new_gallery_container {
            width: 100%;
            margin-bottom: 20px;
            h4 {
                color: $contentColor;
                margin-bottom: 5px;
            }
        }
        .admin_media_video_playlist_container {
            width: 100%;
            h4 {
                color: $contentColor;
                margin-bottom: 5px;
            }
        }
        .admin_media_input_container {
            @include flex(row, flex-start);
            width: 100%;
            input {
                width: 88%;
                height: 40px;
                padding: 10px;
                border-radius: 6px;
                margin-right: 10px;
            }
            button {
                background: $white;
                border: none;
                width: 85px;
                height: 30px;
                border-radius: 6px;
                &:hover {
                    cursor: pointer;
                    background: $offWhite;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        .admin_media_galleries_container {
            @include flex(column, center, flex-start);
            width: 100%;
            background: $white;
            border-radius: 6px;
            margin-bottom: 30px;
        }
    }
}

#admin_media_add_gallery_btn {
    position: relative;
    font-size: 13px;
    transition: all 1s;
    &::before {
        top: 30% !important;
        left: 45% !important;
    }
}

#admin_media_update_playlist_btn {
    position: relative;
    font-size: 13px;
    transition: all 1s;
    &::before {
        top: 30% !important;
        left: 45% !important;
    }
}