@import "../../Styles/variables.scss";


.credits {
    max-width: 970px;
    width: 970px;
    height: 100%;
}

.wall {
    position: relative;
    z-index: 0;
    padding-top: 0;
    margin-bottom: 40px;
    text-align: left;
}

.wall_header {
    background: url("../../Styles/Images/Panel/title.jpg");
    height: 44px;
    width: 970px;
    padding: 5px 20px 0;
    color: #d6ffe4;
    z-index: 2;
    position: relative;
    h3 {
        display: inline;
        margin-top: 0;
        margin-bottom: 10px;
    }
}

.wall_background {
    position: relative;
    background: url("../../Styles/Images/Panel/middle.jpg");
    width: 970px;
    height: 100%;
    z-index: 1;
}

.wall_mid_header {
    background: url("../../Styles/Images/Panel/mid_title.jpg");
    height: 52px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
    color: #d6ffe4;
    margin-top: -5px;
    z-index: 2;
    position: relative;
    h3 {
        display: inline;
        margin-top: 0;
        margin-bottom: 10px;
    }
}

.wall_mid_content_cred {
    padding: 10px 15px;
    overflow: hidden;
    color: $contentColor;
    font-size: 120%;
}

.credits_divider {
    background: url("../../Styles/Images/dividerimage.png");
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 6px;
    width: 564px;
}

.credits_divider_fancy {
    background: url("../../Styles/Images/dividerfancy.png");
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 59px;
    width: 564px
}

.wall_footer_creds {
    background-image: url("../../Styles/Images/Media/gallery_footer.jpg");
    margin-top: 0;
    height: 41px;
}