@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.gallery_card {
    background-image: url("../../../../Styles/Images/Media/gallery_tile.jpg");
    -webkit-box-shadow: 0 0 20px 0 #0a0a0a;
    -moz-box-shadow: 0 0 20px 0 #0a0a0a;
    box-shadow: 0 0 20px 0 #0a0a0a;
    display: inline-block;
    width: 176px;
    height: 126px;
    text-align: center;
    margin: 27px;
    .gallery_card_link {
        display: block;
        padding: 3px;
        transition: opacity .4s;
        opacity: .7;
        height: 100%;
        width: 100%;
        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }
}