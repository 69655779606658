@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.reset_password_panel {
    position: relative;
    z-index: 1;
    text-align: left;
    width: 970px;
    max-width: 970px;
    .reset_password_header {
        background: url("../../Styles/Images/Panel/title.jpg");
        color: $titleColor;
        padding: 10px;
        width: 100%;
    }
    .reset_password_content {
        @include flex(column);
        background: url("../../Styles/Images/Panel/middle.jpg");
        width: 100%;
        .reset_password_input_container {
            @include flex(row);
            position: relative;
            width: 60%;
            p {
                margin-right: 5px;
                margin-bottom: 0;
                color: $contentColor;
                font-size: 15px;
                width: 25%
            }
            input {
                width: 75%;
                height: 30px;
                margin-bottom: 10px;
                padding: 5px;
            }
            img {
                position: absolute;
                right: 35px;
                top: 0;
                width: 30px;
                height: 30px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .reset_password_btn_container {
            float: left;
            width: 60%;
            margin-bottom: 10px;
            button {
                float: right;
                border: none;
                color: $contentColor;
                width: 120px;
                background: $btnPrimaryBackground;
                padding: 10px;
                border-radius: 6px;
                outline: none;
                &:hover {
                    cursor: pointer;
                    background: $btnPrimaryHover;
                }
            }
        }
    }
}

#change_password_btn {
    font-size: 13px;
    transition: all 1s;
    position: relative;
}