@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.about {
    height: 100%;
    width: 100%;
    .about_warning_message_container {
        position: absolute;
        top: 300px;
        right: 0;
        left: 0;
        background: $blackTransparent;
        padding: 100px;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        color: $white;
    }
}

.css3_wrapper {
    position: fixed;
    top: -50px;
    left: -50px;
    right: -50px;
    bottom: -50px;
    min-width: 1200px;
    min-height: 1000px;
    overflow: auto;
}

.parallax_container {
    background-color: #000;
    min-width: 100%;
    min-height: 88%;
    overflow: hidden;
    transition: min-height 1s;
}

.parallax_layer {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;
    transition: all;
}

.parallax_divider {
    min-width: 100%;
}

.flat_wrapper {
    position: absolute;
    top: -18px;
    left: -16px;
    height: 100%;
    width: 100%;
    min-width: 1200px;
    min-height: 900px;
    overflow: hidden;
}

.flat_layer {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    visibility: hidden;
    transition: all 0.6s;
    transform: translate3d(0px, 0px, 0px) !important;
}

.info_category_wrapper {
    position: relative;
    left: 50%;
    width: 1100px;
    margin-left: -550px;
    display: none;
    top: 250px;
}

.info_category {
    -webkit-border-radius: 20px 20px;
    -moz-border-radius: 20px 20px;
    border-radius: 20px 20px;
    position: absolute;
    background-image: url("../../Styles/Images/About/info_panel.jpg");
    border: rgba(255,225,200,.8) 2px solid;
    margin-right: 130px;
    margin-bottom: 30px;
    float: left;
    overflow: hidden;
    transition: top .5s,max-height .5s, opacity 1s;
    max-height: 120px;
    z-index: 2;
    .info_category_top {
        height: 122px;
        padding-right: 10px;
        &:hover {
            .info_name {
                background-position: 0px 122px !important;
            }
        }
    }
    .info_name {
        display: inline-block;
        height: 122px;
        float: left;
        margin-right: 10px;
        margin-top: -1px;
    }
    .info_small_description {
        display: table-cell;
        vertical-align: middle;
        color: $contentColor;
        overflow: hidden;
        line-height: 125%;
        font-size: 130%;
        height: 116px;
    }
    &:active {
        .info_category_top {
            .info_name {
                background-position: 0px 122px !important;
            }
        }
    }
    &.category_right {
        float: right;
        text-align: right;
        margin-right: 0;
        margin-left: 130px;
        .info_name {
            float: right;
            margin-right: 0px;
            margin-left: 10px;
        }
        .info_category_top {
            padding-right: 0;
            padding-left: 10px;
        }
    }
}

.info_gallery {
    float: left;
    width: 970px;
    height: 480px;
    video {
        background-color: #000;
        width: 970px;
        height: 458px;
    }
}

.info_overlay_bg {
    background-image: url("../../Styles/Images/About/info_panel.jpg");
    background-position: 0 -122px;
    position: absolute;
    height: 457px;
    top: 122px;
    width: 315px;
    opacity: .8;
}

.info_overlay {
    position: absolute;
    height: 456px;
    top: 122px;
    width: 315px;
    color: $contentColor;
    font-size: 16px;
    padding: 10px;
    line-height: 22px;
    text-align: left;
}

#dig {
    top: -253px;
    .info_name {
        width: 290px;
        background-image: url("../../Styles/Images/About/dig.jpg");
    }
}

#fight {
    top: -53px;
    .info_name {
        width: 385px;
        background-image: url("../../Styles/Images/About/fight.jpg");
    }
}

#build {
    top: 147px;
    .info_name {
        width: 393px;
        background-image: url("../../Styles/Images/About/build.jpg");
    }
}

#footer_about_version_selector {
    @include flex();
    z-index: 11;
    font-size: 18px;
    p {
        color: $white;
        margin: 0;
    }
    button {
        background-color: transparent;
        border: none;
        color: #bbf;
        font-size: 18px;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.accelerate {
    -webkit-transform: translate3d(0,0,2px);
    -moz-transform: translate3d(0,0,2px);
    -ms-transform: translate3d(0,0,2px);
    -o-transform: translate3d(0,0,2px);
    transform: translate3d(0,0,2px);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}