@import "../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../Styles/variables.scss";

.admin_edit_store_section {
    @include flex(column, center, flex-start);
    width: 100%;
    h4 {
        color: $contentColor;
    }
    .admin_edit_store_section_btn_container {
        @include flex(row, space-between);
        width: 100%;
        button {
            background: $white;
            border: none;
            width: 85px;
            height: 30px;
            border-radius: 6px;
            &:hover {
                cursor: pointer;
                background-color: $offWhite;
            }
            &:focus {
                outline: none;
            }
        }
        .admin_edit_store_section_preview_add_container {
            :first-child {
                margin-right: 5px;
            }
        }
    }
}

#admin_edit_store_section_edit_btn {
    position: relative;
    font-size: 13px;
    transition: all 1s;
}