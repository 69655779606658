@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.single_gallery_panel {
    width: 970px;
    max-width: 970px;
    .wall_header {
        .name_text {
            color: $titleColor;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .wall_content {
        font-size: 0;
    }
    .wall_footer {
        background: url("../../Styles/Images/Panel/bottom.jpg");
        width: 970px;
        height: 78px;
        .wall_footer_content {
            @include flex(row, space-between);
            position: absolute;
            width: 970px;
            text-align: center;
            bottom: 0;
            color: $titleColor;
            .previous_page_btns_container {
                margin-left: 5px;
                font-size: 0;
            }
            .page_info {
                height: 40px;
                padding-top: 8px;
                b {
                    height: 40px;
                    font-size: 85%;
                }
            }
            .next_page_btns_container {
                margin-right: 5px;
                font-size: 0;
            }
            .img_btn {
                margin-bottom: 2px;
                margin-left: 2px;
                margin-right: 2px;
                transition: all .2s;
            }
            .img_btn_active {
                &:hover {
                    cursor: pointer;
                    margin-bottom: 5px;
                }
            }
            .img_btn_divider {
                margin-bottom: -1px;
            }
        }
    }
}