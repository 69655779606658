@import "../../../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../../../Styles/variables.scss";

.admin_all_tiles_preview {
    @include flex(column);
    background: $blackTransparent;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .wall {
        overflow: auto;
        margin-top: 250px;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
            width: 0;
            height: 0;
        }
        .wall_header {
            width: 100%;
                background-image: url("../../../../../../../../../../Styles/Images/Store/Header_Store_Lower.jpg");
                height: 78px;
        }
        .wall_background_custom {
            width: 970px;
            background: url("../../../../../../../../../../Styles/Images/Panel/middle.jpg");
            .wall_content {
                padding: 10px;
                .admin_all_tiles_preview_containers_container {
                    @include flex(row, space-between);
                    flex-wrap: wrap;
                    width: 100%;
                }
            }
        }
        .wall_footer {
            width: 100%;
            height: 78px;
            background: url("../../../../../../../../../../Styles/Images/Panel/bottom_none.jpg");
        }
    }
}