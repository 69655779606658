@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";
.news {
    max-width: 970px;
    width: 970px;
    .news_page_contents {
        @include flex(column, center, flex-end);
        width: 100%;
    }
    .mini_page_wrapper {
        background-image: url("../../Styles/Images/pagination/mini_page_dropshadow.png");
        padding: 6px 6px 6px 5px;
        height: 36px;
        width: 175px;
        margin-bottom: 10px;
        .mini_page_selector {
            display: block;
            float: left;
            height: 24px;
            .mini_page {
                display: block;
                background-image: url("../../Styles/Images/pagination/mini_page_buttons.png");
                height: 24px;
                float: left;
                &:hover {
                    cursor: pointer;
                }
            }
            .mini_first_btn {
                width: 30px;
                background-position: 0 0!important;
            }
            .enabled.mini_first_btn {
                background-position: 186px 0!important;
                &:hover {
                    background-position: 93px 0!important;
                }
            }
            .mini_previous_btn {
                width: 16px;
                background-position: -30px 0!important;
            }
            .enabled.mini_previous_btn {
                background-position: 156px 0!important;
                &:hover {
                    background-position: 62px 0!important;
                }
            }
            .mini_page_content {
                display: block;
                overflow: hidden;
                height: 24px;
                background-image: url("../../Styles/Images/pagination/mini_page_bar.gif");
                width: 72px;
                float: left;
                font-size: 12px;
                color: #f6ffe3;
                padding-top: 3px;
            }
            .mini_next_btn {
                width: 16px;
                background-position: -46px 0!important;
            }
            .enabled.mini_next_btn {
                background-position: 141px 0!important;
                &:hover {
                    background-position: 48px 0!important;
                }
            }
            .mini_last_btn {
                width: 30px;
                background-position: -62px 0!important;
            }
            .enabled.mini_last_btn {
                background-position: 124px 0!important;
                &:hover {
                    background-position: 31px 0!important;
                }
            }
        }
    }
}