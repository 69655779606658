@import "../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../Styles/variables.scss";

.store_link {
    margin: 0 5px 5px 5px;
    height: 80px;
    a {
        display: block;
        width: 50px;
        height: 80px;
        .store_link_img {
            width: 50px !important;
            height: 50px !important;
            border: none !important;
        }
        p {
            margin: 0 !important;
            width: 50px !important;
            height: 25px !important;
        }
    }
}