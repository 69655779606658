@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.store_home_tiles {
    width: 970px;
    margin-bottom: 20px;
    .home_tiles_header {
        width: 100%;
        height: 78px;
        background: url("../../../../Styles/Images/Store/Header_Store_Lower.jpg");
    }
    .wall_content {
        padding: 10px;
    }
    .drop_down_menus_container {
        @include flex(row, flex-start);
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
    .tile_containers_container {
        @include flex(row, space-between);
        flex-wrap: wrap;
        width: 950px;
    }
}