@import "../../Styles/variables.scss";

.navigation {
    background-image: url("../../Styles//Images/Navbar/bar.png");
    position: relative;
    list-style-type: none;
    height: 117px;
    width: 907px;
    margin: -25px auto 20px;
    padding: 0;
    z-index: 2;
}

.nav_edge_left {
    display: block;
    float: left;
    height: 44px;
    width: 76px;
}

.nav_btn {
    margin-top: 32px;
    display: block;
    float: left;
    height: 44px;
    opacity: 0;
    transition: opacity .3s;
    background-image: url("../../Styles/Images/Navbar/active_bar.jpg");
    &:hover {
        opacity: 1;
        cursor: pointer;
    }
    .acitve {
        opacity: 1;
    }
    a {
        display: inline-block;
        width: 100%;
        height: 100%;
    }
}


.nav_btn_home {
    width: 78px;
    background-position: -9px 0 !important;
}

.nav_btn_news {
    width: 81px;
    background-position: -87px 0 !important;
}

.nav_btn_about {
    width: 79px;
    background-position: -168px 0 !important;
}

.nav_btn_credits {
    width: 88px;
    background-position: -247px 0 !important;
}

.nav_btn_buy {
    background-image: url("../../Styles/Images/Navbar/bar.png");
    width: 99px;
    height: 117px;
    margin-top: 0;
    background-position: -897px 0 !important;
}

.nav_btn_media {
    width: 93px;
    background-position: -349px 0 !important;
}

.nav_btn_wiki {
    width: 81px;
    background-position: -442px 0 !important;
}

.nav_btn_forums {
    width: 80px;
    background-position: -523px 0 !important;
}

.nav_btn_store {
    width: 79px;
    background-position: -603px 0 !important;
}

.nav_edge_right {
    display: block;
    float: left;
    height: 44px;
    width: 72px;
}

.nav_btn span {
    display: none
}

.nav_btn.active {
    opacity: 1;
}

.buy_container {
    position: relative;
    width: 156px;
    height: 1px;
    margin: 0 auto;
    z-index: 4;
}

.buy {
    position: absolute;
    background-image: url("../../Styles/Images/Navbar/buy_bar.png");
    width: 168px;
    height: 56px;
    text-align: left;
    vertical-align: top;
    opacity: 0;
    top: 70px;
    margin-top: -20px;
    transition: all .4s;

    

    &:hover {
        text-decoration: none;
        color: $contentColor;
    
        .buy-icon-active {
            opacity: 1;
        }
    }

    .buy_text {
        display: inline-block;
        font-size: 22px;
        color: $contentColor;
        margin-top: 8px;
        margin-left: 10px;
    }

    .buy_icon {
        position: relative;
        display: block;
        float: left;
        margin-top: 5px;
        margin-left: 4px;
        background-image: url("../../Styles/Images/Navbar/buy_icons.gif");
        width: 42px;
        height: 43px;

        .buy_icon_active {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            background-image: url("../../Styles/Images/Navbar/buy_icons.gif");
            transition: opacity .3s;
        }
    }

    &:hover {
		text-decoration: none;
		color: $contentColor;

		.buy_icon_active {
			opacity: 1;
		}
	}

    &#xbla {
        left: -490px;
        transition-delay: 0.0s;
        .buy_icon {
            background-position: 0px 0px !important;
        }
        .buy_icon_active {
            background-position: 0px -43px !important;
        }	
    }
    
    &#switch {
        left: -350px;
        transition-delay: 0.075s;
        .buy_icon {
            background-position: -252px 0px !important;
        }
        .buy_icon_active {
            background-position: -252px -43px !important;
        }
    }
    
    &#psn {
        left: -210px;
        transition-delay: 0.125s;
        .buy_icon {
            background-position: -126px 0px !important;
        }
        .buy_icon_active {
            background-position: -126px -43px !important;
        }
    }
    
    &#psvita {
        left: -70px;
        transition-delay: 0.2s;
        .buy_icon {
            background-position: -84px 0px !important;
        }
        .buy_icon_active {
            background-position: -84px -43px !important;
        }
    }
    
    &#pc {
        left: 70px;
        transition-delay: 0.275s;
        .buy_icon {
            background-position: -42px 0px !important;
        }
        .buy_icon_active {
            background-position: -42px -43px !important;
        }
    }
    
    &#android {
        left: 210px;
        transition-delay: 0.325s;
        .buy_icon {
            background-position: -210px 0px !important;
        }
        .buy_icon_active {
            background-position: -210px -43px !important;
        }
    }	
    
    &#ios {
        left: 350px;
        transition-delay: 0.4s;
        .buy_icon {
            background-position: -168px 0px !important;
        }
        .buy_icon_active {
            background-position: -168px -43px !important;
        }
    }
    
    &#amazon {
        left: 490px;
        transition-delay: 0.525s;
        .buy_icon {
            background-position: -294px 0px !important;
        }
        .buy_icon_active {
            background-position: -294px -43px !important;
        }
    }
}