@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.drop_down_menu {
    @include flex(row, space-between);
    position: relative;
    margin-right: 10px;
    &:hover {
        .drop_down_btn {
            cursor: pointer;
            background: $inputTransparent;
        }
        .drop_down_btn_active {
            background: rgba(86, 47, 33, 0.5) !important;
        }
        .drop_down_arrow_container {
            cursor: pointer;
            background: $inputArrowBoxTransparent;
        }
        .drop_down_arrow_container_active {
            background: rgba(86, 47, 33, 0.5) !important;
        }
    }
    .drop_down_btn {
        background: transparent;
        height: 30px;
        border: none;
        color: $titleColor;
        font-weight: 700;
        font-size: 18px;
    }
    .drop_down_btn_active {
        border-top-right-radius: 2px;
        border-bottom-left-radius: 2px;
        background: rgba(86, 47, 33, 0.5);
        box-shadow: -5px 5px 10px -6px rgb(0, 0, 0 / 35%);
        color: $activeDropDownColor;
    }
    .drop_down_arrow_container_active {
        background: rgba(86, 47, 33, 0.5) !important;
        box-shadow: 7px 5px 10px -6px rgb(0, 0, 0 / 35%);
    }
    .drop_down_content {
        position: absolute;
        display: none;
        background: url("../../../../../../Styles/Images/Store/drop_down_background.jpg");
        min-width: 240px;
        border-radius: 3px;
        border-top-left-radius: 0;
        top: 30px;
        max-height: 80vh;
        overflow: auto;
        background-position: top right;
        button {
            background: transparent;
            color: $activeDropDownColor;
            width: 240px;
            padding: 6px 15px 6px 12px;
            border: none;
            text-align: left;
            font-weight: 700;
            font-size: 15px;
            &:hover {
                cursor: pointer;
                background: $dropDownBtnHover;
            }
        }

    }
    .drop_down_content_show {
        display: block;
    }
    .drop_down_arrow_container {
        @include flex(column);
        height: 30px;
        width: 30px;
        &:hover {
            .drop_down_arrow_down {
                border-top: 6px solid $titleColor;
            }
        }
        .drop_down_arrow_down {
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid $inputArrowTransparent;
        }
        .drop_down_arrow_up {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $titleColor;
        }
    }
}