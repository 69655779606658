@import "../../../../../../../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../../../../../../../Styles/variables.scss";

.admin_featured_product_published_btn {
    position: relative;
    width: 100px !important;
    font-size: 13px;
    transition: all 1s;
    background: $lightBlue !important;
    &:hover {
        background: $lightBlueHover !important;
    }
}

.admin_featured_product_unpublished_btn {
    position: relative;
    width: 100px !important;
    font-size: 13px;
    transition: all 1s;
    background: $lightOrange !important;
    &:hover {
        background: $lightOrangeHover !important;
    }
}

.admin_featured_product_unfeature_btn {
    width: 100px !important;
}