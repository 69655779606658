@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.ql-font-open-sans, .ql-font span[data-value="open-sans"]::before {
    font-family: "Open Sans";
}

.ql-font-arial, .ql-font span[data-value="arial"]::before {
    font-family: Arial, sans-serif;
}

.ql-font-sans-serif, .ql-font span[data-value="sans-serif"]::before {
    font-family: sans-serif;
}

.ql-font-comic-sans, .ql-font span[data-value="comic-sans"]::before {
    font-family: "Comic Sans MS", cursive, sans-serif
}

.ql-font-georgia, .ql-font span[data-value="georgia"]::before {
    font-family: Georgia, 'Times New Roman', Times, serif
}

.ql-font-helvetica, .ql-font span[data-value="helvetica"]::before {
    font-family: Helvetica, sans-serif
}

.ql-font-lucida, .ql-font span[data-value="lucida"]::before {
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif
}

.admin_edit_post_panel {
    @include flex (column);
    width: 100%;
    .admin_edit_post_header {
        background: $offWhite;
        width: 100%;
        text-align: center;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
    .admin_edit_post_form {
        background: $white;
        width: 100%;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        margin-bottom: 50px;
        .admin_edit_post_input_container {
            width: 100%;
            padding: 10px;
            .admin_quill_editor {
                height: 200px;
                width: 100%;
                margin-bottom: 65px;
                img {
                    max-width: 100% !important;
                }
                iframe {
                    width: 640px;
                    height: 360px;
                    max-width: 100% !important;
                }
            }
            input {
                width: 100%;
                height: 30px;
                padding: 5px;
            }
            .title_characters {
                font-size: 70%;
                margin: 0;
                text-align: end;
            }
        }
        .admin_edit_post_social_media_container {
            h4 {
                text-align: center;
            }
            h6 {
                margin: 0;
            }
            .admin_edit_post_link_container {
                width: 95%;
                border-bottom: 1px solid $purpleBorder;
                margin: auto;
            }
            .admin_edit_post_social_character_limit {
                position: absolute;
                top: 5px;
                right: 10px;
                p {
                    margin: 0;
                }
            }
            .admin_edit_post_input_container {
                position: relative;
            }
        }
        .admin_edit_post_featured_container {
            @include flex(column);
            .admin_edit_post_featured_message_container {
                width: 95%;
                border-radius: 6px;
                margin-top: 10px;
                margin-bottom: 10px;
                border: 0.1px solid rgba($color: #000000, $alpha: 0.25);
                h6 {
                    text-align: center;
                }
                &:hover {
                    cursor: pointer;
                }
            }
            .admin_edit_post_featured_true {
                background: $lightBlue;
            }
            .admin_edit_post_featured_false {
                background: $lightOrange;
            }
            .admin_edit_post_featured_input_container {
                @include flex(row);
                .admin_edit_post_input_container {
                    textarea {
                        width: 100%;
                    }
                    .featured_img_input_container {
                        @include flex(row, flex-start);
                        .upload_featured_img_btn {
                            width: 80px;
                            height: auto;
                            margin-right: 10px;
                            padding: 0;
                            &:focus {
                                outline: none;
                            }
                            &::-webkit-file-upload-button {
                                visibility: hidden;
                            }
                            &::before {
                                content: "Browse...";
                                display: inline-block;
                                background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
                                color: $black;
                                border: 1px solid $black;
                                padding: 5px 8px;
                                outline: none;
                            }
                            &:hover {
                                cursor: pointer;
                            }
                        }
                        p {
                            margin: 0;
                        }
                    }
                }
            }
            .admin_edit_post_preview_container {
                border-left: 1px solid $purpleBorder;
                .news_block_shadow {
                    position: relative;
                    width: 300px;
                    height: 328px;
                    margin-top: 30px;
                    margin-left: 30px;
                    .news_block_grass {
                        background: url("../../../../../../Styles/Images/Wall/news_block_grass.png");
                        position: relative;
                        width: 300px;
                        height: 10px;
                    }
                    .news_block {
                        position: relative;
                        width: 300px;
                        height: 318px;
                        overflow: hidden;
                        background: url("../../../../../../Styles/Images/Home/news_block.jpg");
                        padding: 0 10px 10px 10px;
                        .news_block_image_container {
                            position: absolute;
                            top: 15px;
                            left: 19px;
                            .fading_img {
                                opacity: .5;
                                transition: opacity .5s;
                                -webkit-transition: opacity .5s;
                                -o-transition: opacity .5s;
                                &:hover {
                                    opacity: 1;
                                }
                            }
                            img {
                                width: 263px;
                                height: 181px;
                                background-position: center !important;
                                background-size: 100% auto !important;
                            }
                        }
                        h4 {
                            color: $titleColor;
                            text-align: center;
                            margin-bottom: 2px;
                            margin-top: 208px;
                            font-size: 18px;
                        }
                        p {
                            color: $contentColor;
                            line-height: 120%;
                            margin-bottom: 3px;
                            font-size: 15px;
                            text-align: justify;
                        }
                        .read_more {
                            @include flex(row);
                            position: absolute;
                            font-size: 16px;
                            right: 16px;
                            bottom: 4px;
                            p {
                                color: $titleColor
                            }
                        }
                        .news_date {
                            position: absolute;
                            color: $titleColor;
                            font-size: 16px;
                            left: 10px;
                            bottom: 4px;
                        }
                    }
                }
            }
        }
    }
    .admin_edit_post_btn_container {
        @include flex(row, space-between);
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        .admin_cancel_post_btn {
            right: 5px;
            top: 5px;
            background: $white;
            border: 1px solid $black;
            padding: 8px 15px;
            border-radius: 3px;
            margin-left: 10px;
            width: 100px;
            &:focus {
                outline: none;
            }
            &:hover {
                cursor: pointer;
                background: $offWhite;
            }
        }
        .admin_edit_post_preview_submit_container {
            button {
                background: $white;
                border: 1px solid $black;
                padding: 8px 15px;
                border-radius: 3px;
                margin-right: 10px;
                width: 100px;
                &:focus {
                    outline: none;
                }
                &:hover {
                    cursor: pointer;
                    background: $offWhite;
                }
            }
        }
    }
    .admin_edit_post_preview_post {
        max-width: 100% !important;
        .wall_grass_top {
            max-width: 100% !important;
        }
        .wall {
            position: relative;
            padding-top: 0;
            margin-bottom: 40px;
            .wall_header {
                position: relative;
                background: url("../../../../../../Styles/Images/Panel/title.jpg");
                height: 44px;
                max-width: 100% !important;
                padding: 5px 20px 0;
                color: $titleColor;
                z-index: 2;
                h3 {
                    display: inline;
                    margin-top: 0;
                    margin-bottom: 10px;
                    max-width: 100% !important;
                    overflow: hidden;
                    margin-right: 10px;
                }
            }
            .wall_background {
                position: relative;
                background: url("../../../../../../Styles/Images/Panel/middle.jpg");
                max-width: 100% !important;
                height: 100%;
                z-index: 1;
                .wall_fade_in {
                    background: url("../../../../../../Styles/Images/Panel/fade_in.jpg");
                    max-width: 100% !important;
                    height: 138px;
                }
                .wall_content {
                    padding: 10px 50px 55px;
                    max-width: 100% !important;
                    img {
                        max-width: 100% !important;
                    }
                    iframe {
                        width: 640px;
                        height: 360px;
                        max-width: 100% !important;
                    }
                }
                .wall_fade_out {
                    background: url("../../../../../../Styles/Images/Panel/fade_out_dark.png");
                    max-width: 100% !important;
                    height: 106px;
                    margin-top: -184px;
                }
                .wall_footer {
                    background: url("../../../../../../Styles/Images/Panel/bottom.jpg");
                    max-width: 100% !important;
                    height: 78px;
                    .wall_footer_timestamp {
                        @include flex(row, flex-start);
                        font-style: italic;
                        position: absolute;
                        max-width: 100% !important;
                        left: 20px;
                        bottom: 10px;
                        color: $titleColor;
                        font-size: 90%;
                        :nth-child(1) {
                            margin-right: 5px;
                        }
                        p {
                            margin: 0;
                        }
                        .utc_date_time {
                            @include flex(row, flex-start);
                        }
                    }
                    .social_media {
                        position: absolute;
                        opacity: 0.7;
                        width: 29px;
                        height: 29px;
                        transition: opacity .2s;
                        -webkit-transition: opacity .2s;
                        bottom: 7px !important;
                        &:hover {
                            cursor: pointer;
                            opacity: 1;
                        }
                    }
                    .post_link {
                        background: url("../../../../../../Styles/Images/Social/link.png");
                        color: $titleColor;
                        right: 85px;
                        transition: right .4s;
                    }
                    .post_link_text {
                        -webkit-border-radius: 5px 5px;
                        -moz-border-radius: 5px/5px;
                        border-radius: 5px/5px;
                        position: absolute;
                        bottom: 8px;
                        right: 85px;
                        width: 0;
                        padding: 3px 0 0 5px;
                        opacity: 0;
                        transition: all .4s;
                    }
                    .twitter {
                        background: url("../../../../../../Styles/Images/Social/twitter.png");
                        bottom: 10px;
                        right: 50px;
                        color: $titleColor
                    }
                    .facebook {
                        background: url("../../../../../../Styles/Images/Social/facebook.png");
                        bottom: 10px;
                        right: 10px;
                    }
                }
            }
        }
    }
}

#admin_edit_post_btn {
    position: relative;
    font-size: 13px;
    transition: all 1s;
}