@import "../../../../../../Styles/mixins.scss";
@import "../../../../../../Styles/variables.scss";

.admin_view_posts_panel {
    position: relative;
    width: 775px;
    margin-top: -30px;
    margin-left: -20px;
    .admin_view_posts_panel_content {
        width: 100%;
        .admin_view_posts_header {
            @include flex(row, space-between);
            width: 100%;
            background: $whiteTransparent;
            border: 1px solid $white;
            border-radius: 6px;
            padding: 5px;
            margin-bottom: 20px;
            h4 {
                margin: 0;
                color: $grey;
            }
            .admin_new_post_btn {
                background: $white;
                border: none;
                padding: 8px 15px;
                border-radius: 3px;
                margin-top: 5px;
                &:focus {
                    outline: none;
                }
                &:hover {
                    cursor: pointer;
                    background: $offWhite;
                }
            }
        }
        .admin_view_posts_posts_container {
            background: $white;
            width: 100%;
            border-radius: 6px;
            margin-bottom: 20px;
        }
        .admin_view_posts_page_btn_container {
            @include flex(row, space-between);
            width: 100%;
            button {
                background: $white;
                border: none;
                border-radius: 15px;
                padding: 10px 15px;
                font-size: 120%;
                color: $green;
                &:focus {
                    outline: none;
                }
                &:disabled {
                    color: $lightGrey;
                }
                &:hover {
                    cursor: pointer;
                    background: $offWhite;
                }
            }
        }
    }
}