@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.single_news_post {
    .wall {
        position: relative;
        .wall_header {
            background: url("../../../../Styles/Images/Panel/title.jpg");
            height: 44px;
            width: 970px;
            padding: 5px 20px 0;
            color: $titleColor;
            z-index: 2;
            position: sticky;
            top: 0;
            h3 {
                display: inline;
                margin-top: 0;
                margin-bottom: 10px;
                margin-right: 10px;
            }
            .post_author {
                margin-top: -10px;
            }
            .social_media {
                position: absolute;
                opacity: .7;
                width: 29px;
                height: 29px;
                transition: opacity .2s;
                -webkit-transition: opacity .2s;
                &:hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }
            .up_arrow {
                background: url("../../../../Styles/Images/up_arrow.png");
                bottom: 12px;
                right: 10px;
                width: 25px;
                height: 25px;
                color: $titleColor;
            }
        }
        .wall_background {
            position: relative;
            background: url("../../../../Styles/Images/Panel/middle.jpg");
            width: 970px;
            height: 100%;
            z-index: 1;
            .wall_content {
                padding: 10px 50px 55px;
                max-width: 100%;
                img {
                    max-width: 100% !important;
                }
                iframe {
                    width: 640px;
                    height: 360px;
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
            .wall_footer {
                background: url("../../../../Styles/Images/Panel/bottom.jpg");
                width: 970px;
                height: 78px;
                .wall_footer_timestamp {
                    @include flex(row, flex-start);
                    font-style: italic;
                    position: absolute;
                    width: 970px;
                    left: 20px;
                    bottom: 10px;
                    color: $titleColor;
                    font-size: 90%;
                    :nth-child(1) {
                        margin-right: 5px;
                    }
                    p {
                        margin: 0;
                    }
                    .utc_date_time {
                        @include flex(row, flex-start);
                    }
                }
                .social_media {
                    position: absolute;
                    opacity: 0.7;
                    width: 29px;
                    height: 29px;
                    transition: opacity .2s;
                    -webkit-transition: opacity .2s;
                    bottom: 7px !important;
                    &:hover {
                        cursor: pointer;
                        opacity: 1;
                    }
                }
                .post_link {
                    background: url("../../../../Styles/Images/Social/link.png");
                    color: $titleColor;
                    right: 85px;
                    transition: right .4s;
                }
                .post_link_text {
                    -webkit-border-radius: 5px 5px;
                    -moz-border-radius: 5px/5px;
                    border-radius: 5px/5px;
                    position: absolute;
                    bottom: 8px;
                    right: 85px;
                    width: 0;
                    padding: 3px 0 0 5px;
                    opacity: 0;
                    transition: all .4s;
                }
                .twitter {
                    background: url("../../../../Styles/Images/Social/twitter.png");
                    bottom: 10px;
                    right: 50px;
                    color: $titleColor
                }
                .facebook {
                    background: url("../../../../Styles/Images/Social/facebook.png");
                    bottom: 10px;
                    right: 10px;
                }
            }
        }
    }
}