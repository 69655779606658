@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.add_user_container {
    @include flex(column, center, flex-start);
    margin-bottom: 50px;
    h3 {
        color: $contentColor;
        width: 100%;
    }
    h4 {
        color: $contentColor;
    }
    .add_user_input {
        @include flex(column, center, flex-start);
        width: 100%;
        input {
            margin-bottom: 20px;
            width: 100%;
            height: 30px;
            border-radius: 6px;
        }
        .email_input_error {
            border: 1px solid $errorText;
        }
        .password_input {
            position: relative;
            @include flex(row);
            margin-bottom: 20px;
            width: 100%;
            input {
                margin-right: 5px;
            }
            img {
                position: absolute;
                right: 15px;
                width: 30px;
                height: 30px;
                margin-bottom: 20px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .admin_add_user_btn_container {
            width: 100%;
            float: left;
            button {
                float: right;
                border: none;
                color: $contentColor;
                width: 100px;
                background: $btnPrimaryBackground;
                padding: 10px;
                border-radius: 6px;
                outline: none;
                &:hover {
                    cursor: pointer;
                    background: $btnPrimaryHover;
                }
            }
        }
    }
}

.email_warning {
    width: 100%;
    background: $errorBackground;
    color: $errorText;
    border: 1px solid $errorBorder;
    margin-bottom: 20px;
    p {
        margin-bottom: 0;
        padding: 5px;
    }
}

.current_users_container {
    h3 {
        color: $contentColor
    }
}

#add_user_btn {
    position: relative;
    font-size: 13px;
    transition: all 1s;
}