@import "../../../../../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../../../../../Styles/variables.scss";

.admin_section_category {
    @include flex(row, space-between);
    width: 100%;
    border-bottom: 1px solid $black;
    padding: 10px;
    p {
        margin: 0;
    }
    .admin_section_category_btn_container {
        button {
            background: $white;
            margin-right: 5px;
            border: 1px solid black;
            border-radius: 6px;
            padding: 2px 5px;
            width: 50px;
            height: 20px;
            &:hover {
                cursor: pointer;
                background: $offWhite;
            }
            &:focus {
                outline: none;
            }
        }
        :first-child {
            width: 75px;
        }
    }
}

#admin_section_category_delete_btn {
    position: relative;
    font-size: 13px;
    transition: all 1s;
}