@import "../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../Styles/variables.scss";

.admin_add_container {
    width: 100%;
    .admin_add_container_container_type_selector_container {
        @include flex(row);
        width: 100%;
        .admin_add_container_btn_container {
            @include flex(row, flex-start);
            width: 60%;
            flex-wrap: wrap;
            .admin_add_container_container_btn {
                text-align: center;
                border: 1px solid $black;
                &:hover {
                    cursor: pointer;
                    background: $offWhite;
                }
            }
            .admin_add_container_one_img_one_by_two {
                @include flex(column);
                width: 200px;
                height: 250px;
                padding: 10px;
                .admin_add_container_img_div {
                    @include flex(column);
                    border: 1px solid $brownBorder;
                    width: 180px;
                    height: 230px;
                }
            }
            .admin_add_container_two_img_one_by_two {
                @include flex(column);
                width: 200px;
                height: 250px;
                padding: 10px;
                :first-child {
                    margin-bottom: 10px;
                }
                .admin_add_container_img_div {
                    @include flex(column);
                    width: 180px;
                    height: 110px;
                    border: 1px solid $brownBorder;
                }
            }
            .admin_add_container_two_img_two_by_one {
                @include flex(row);
                width: 400px;
                height: 140px;
                padding: 10px;
                :first-child {
                    margin-right: 10px;
                }
                .admin_add_container_img_div {
                    @include flex(column);
                    border: 1px solid $brownBorder;
                    width: 200px;
                    height: 120px;
                }
            }
            .admin_add_container_one_img_two_by_one {
                @include flex(column);
                width: 400px;
                height: 140px;
                padding: 10px;
                .admin_add_container_img_div {
                    @include flex(column);
                    border: 1px solid $brownBorder;
                    width: 380px;
                    height: 120px;
                }
            }
            .admin_add_container_one_img_two_by_two {
                @include flex(column);
                width: 400px;
                height: 250px;
                padding: 10px;
                .admin_add_container_img_div {
                    @include flex(column);
                    border: 1px solid $brownBorder;
                    width: 380px;
                    height: 230px;
                }
            }
            #admin_add_container_one_img_one_by_two_active {
                background: $green;
            }
            #admin_add_container_one_img_one_by_two {
                background: $white;
            }
            #admin_add_container_two_img_one_by_two_active {
                background: $green
            }
            #admin_add_container_two_img_one_by_two {
                background: $white;
            }
            #admin_add_container_two_img_two_by_one_active {
                background: $green;
            }
            #admin_add_container_two_img_two_by_one {
                background: $white;
            }
            #admin_add_container_one_img_two_by_one_active {
                background: $green;
            }
            #admin_add_container_one_img_two_by_one {
                background: $white;
            }
            #admin_add_container_one_img_two_by_two_active {
                background: $green;
            }
            #admin_add_container_one_img_two_by_two {
                background: $white;
            }
        }
    }
}