// colors
$titleColor: #d6ffe4;
$contentColor: #F6FFE3;
$white: #FFF;
$grey: #555555;
$black: rgb(0,0,0);
$errorText: #a94442;
$errorBackground: #f2dede;
$errorBorder: #ebccd1;
$panelBorder: #ddd;
$btnPrimaryBackground: #337ab7;
$btnPrimaryBorder: #2e6da4;
$btnPrimaryHover: #286090;
$formFocus:  #66afe9;
$formShadow: rgba(102,175,233,.6);
$blackTransparent: rgba(0,0,0,.4);
$footerLink: #bbf;
$spinnerColor: #999;
$spinnerBottomColor: #555;
$anchorNormalColor: #337AB7;
$offWhite: #F5F5F5;
$purpleBorder: #DDDDFF;
$lightBlue: #DDEEFF;
$lightOrange: #FFDDCC;
$whiteTransparent: rgba(255, 255, 255, 0.5);
$postAuthorPurple: #A979FF;
$offWhite: rgb(240, 240, 240);
$lightBlueHover: #b6d1ed;
$lightOrangeHover: #efbfa7;
$lightGreen: #D6FFE9;
$lightGrey: #847784;
$green: #00ce5f;
$brownBorder: #a78956;
$darkGrey : #323335;
$inputBorder: #976B45;
$inputTransparent: rgba(255, 255, 255, 0.24);
$inputArrowBoxTransparent: rgba(255, 255, 255, 0.14);
$inputArrowTransparent: rgba(214, 255, 228, 0.65);
$activeDropDownColor: #edfdef;
$dropDownBtnHover: rgba(0,0,0,.4);
$storeInputBorder: #976B45;
$storeInputColor: rgb(234, 227, 209);
$searchIconColor: #7CF7A7;
$storeCategoryActive: #7CF7A7;