@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.gallery_img_container {
    position: relative;
    display: inline-block;
    width: 170px;
    height: 120px;
    margin: 8px;
    &:hover {
        cursor: pointer;
    }
    img {
        position: absolute;
        background: $black;
        -webkit-box-shadow: 0 0 10px 0 #0a0a0a;
        -moz-box-shadow: 0 0 10px 0 #0a0a0a;
        box-shadow: 0 0 10px 0 #0a0a0a;
        transition: all .2s;
        &:hover {
            transform: translate(0px, -5px);
		    -webkit-transform: translate(0px, -5px);
            -moz-transform: translate(0px, -5px);
            -webkit-box-shadow: 0 5px 20px 0 #0a0a0a;
            -moz-box-shadow: 0 5px 20px 0 #0a0a0a;
            box-shadow: 0 5px 20px 0 #0a0a0a;
        }
    }
}