@import "../../../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../../../Styles/variables.scss";

.admin_view_products {
    width: 100%;
    .admin_view_products_page_info_container {
        @include flex(row, space-between);
        width: 100%;
        background: $whiteTransparent;
        border: 1px solid $white;
        border-radius: 6px;
        padding: 5px;
        margin-bottom: 20px;
        h4 {
            margin: 0;
            color: $grey;
        }
        .admin_view_products_new_btn {
            background: $white;
            border: none;
            padding: 8px 15px;
            border-radius: 3px;
            margin-top: 5px;
            &:focus {
                outline: none;
            }
            &:hover {
                cursor: pointer;
                background: $offWhite;
            }
        }
    }
    .admin_view_products_products_container {
        width: 100%;
        background: $white;
        border-radius: 6px;
        margin-bottom: 10px;
    }
    .admin_view_products_btn_container {
        @include flex(column, center, flex-start);
        width: 100%;
        .admin_view_products_prev_next_container {
            @include flex(row, space-between);
            width: 100%;
            button {
                background: $white;
                border: none;
                border-radius: 15px;
                padding: 10px 15px;
                font-size: 120%;
                color: $green;
                &:focus {
                    outline: none;
                }
                &:disabled {
                    color: $lightGrey;
                }
                &:hover {
                    cursor: pointer;
                    background: $offWhite;
                }
            }
            margin-bottom: 10px;
        }
        .admin_view_products_categories_btn {
            background: $white;
            border: none;
            padding: 8px 15px;
            border-radius: 3px;
            margin-top: 5px;
            &:focus {
                outline: none;
            }
            &:hover {
                cursor: pointer;
                background: $offWhite;
            }
        }
    }
}