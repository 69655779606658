@import "../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../Styles/variables.scss";

.admin_add_store_sections {
    @include flex(column, center, flex-start);
    width: 100%;
    .admin_add_store_section_container {
        width: 100%;
        margin-bottom: 20px;
        h4 {
            color: $contentColor;
        }
        .admin_store_section_btn_container {
            @include flex(row, space-between);
            width: 100%;
            button {
                background: $white;
                border: none;
                width: 85px;
                height: 30px;
                border-radius: 6px;
                &:hover {
                    cursor: pointer;
                    background-color: $offWhite;
                }
                &:focus {
                    outline: none;
                }
            }
            :first-child {
                margin-right: 5px;
            }
        }
    }
    .admin_all_store_sections_container {
        width: 100%;
        background: $white;
        border-radius: 6px;
        margin-bottom: 20px;
    }
}

#admin_store_section_add_btn {
    position: relative;
    font-size: 13px;
    transition: all 1s;
}