@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.logo {
    background: url("../../Styles/Images/logo.png");
    position: relative;
    width: 421px;
    height: 140px;
    margin: 0 auto;
    z-index: 2;
}

.wall_grass_top {
    position: relative;
    background-image: url("../../Styles/Images/Wall/wall_top_grass.png");
    width: 970px;
    height: 13px;
}

.panel_dropshadow {
    position: relative;
    &::before {
        -webkit-box-shadow: 0 0 10px 0 #0a0a0a;
        -moz-box-shadow: 0 0 10px 0 #0a0a0a;
        box-shadow: 0 0 10px 0 #0a0a0a;
        content: ".";
        position: absolute;
        top: 5px;
        left: 0;
        bottom: 0;
        width: 100%;
    }
}

.trailer_panel {
    background: url("../../Styles/Images/Home/trailer_block.jpg");
    padding: 15px;
    padding-top: 7px;
    display: inline-block;
    width: 970px;
    height: 396px;
    position: relative;
    text-align: left;
}

.trailer {
    float: left;
    width: 644px;
    height: 361px;
    margin-left: 5px;
    margin-top: 8px;
    background: url("../../Styles/Images/Home/trailer_button.jpg");
    color: #fff;
    margin-right: 15px;
}

.fading_image {
    opacity: .5;
    transition: opacity .5s;
    -webkit-transition: opacity .5s;
}

.home_iframe {
    height: 363px;
    margin-top: 0;
    width: 645px;
    margin-left: 0;
}

.main_content_right {
    text-align: left;
    padding-top: 8px;
    h3 {
        color: $titleColor;
        text-align: center;
        margin-top: 0;
        margin-bottom: 18px;
        font-size: 26px;
    }
    p {
        color: $contentColor;
        line-height: 120%;
        font-size: 17px;
    }
}

.social_media {
    position: absolute;
    opacity: 0.7;
    width: 29px;
    height: 29px;
    transition: opacity .2s;
    -webkit-transition: opacity .2s;
    &:hover {
        opacity: 1;
    }
}

.twitter {
    background: url("../../Styles/Images/Social/twitter.png");
    bottom: 10px;
    right: 50px;
}

.facebook {
    background: url("../../Styles/Images/Social/facebook.png");
    bottom: 10px;
    right: 10px;
}

strong {
    font-weight: 700;
}

a span {
    display: none;
}

h1, .h1 {
    font-size: 36px;
}

h2, .h2 {
    font-size: 30px;
}

h3, .h3 {
    font-size: 24px;
}

h4, .h4 {
    font-size: 18px;
}

h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

.home {
    max-width: 970px;
    width: 970px;
    .news_container {
        @include flex(row, space-between);
        text-align: justify;
        min-width: 970px;
        margin-bottom: 40px;
    }
}