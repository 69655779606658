@import "../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../Styles/variables.scss";

.admin_store_view_home_drop_down_menus {
    width: 100%;
    .admin_store_view_home_drop_down_menus_page_info {
        @include flex(row, space-between);
        width: 100%;
        background: $whiteTransparent;
        border: 1px solid $white;
        border-radius: 6px;
        padding: 5px;
        margin-bottom: 20px;
        h4 {
            margin: 0;
            color: $grey;
        }
        .admin_store_view_home_drop_down_menus_new_btn {
            background: $white;
            border: none;
            padding: 8px 15px;
            border-radius: 3px;
            margin-top: 5px;
            &:focus {
                outline: none;
            }
            &:hover {
                cursor: pointer;
                background: $offWhite;
            }
        }
    }
    .admin_store_view_home_drop_down_menus_drop_down_menus_container {
        background: $white;
        width: 100%;
        border-radius: 6px;
        margin-bottom: 20px;
    }
    .admin_store_view_home_drop_down_menus_prev_next_container {
        @include flex(row, space-between);
        width: 100%;
        margin-bottom: 10px;
        button {
            background: $white;
            border: none;
            border-radius: 15px;
            padding: 10px 15px;
            font-size: 120%;
            color: $green;
            &:focus {
                outline: none;
            }
            &:disabled {
                color: $lightGrey;
            }
            &:hover {
                cursor: pointer;
                background: $offWhite;
            }
        }
    }
}