@import "../../../../Styles/mixins.scss";
@import "../../../../Styles/variables.scss";

.videos_block {
    width: 100%;
    #video_gallery {
        width: 1005;
        .wall_header {
            width: 100%;
            background-image: url("../../../../Styles/Images/Media/video_title.jpg");
            height: 78px;
        }
        .wall_content {
            width: 100%;
            padding: 45px 10px;
            .video_block {
                @include flex(column);
                width: 100%;
                .playlist_error_container {
                    width: 100%;
                    h1 {
                        text-align: center;
                        font-size: 205%;
                    }
                }
                .playlist_container {
                    @include flex(column);
                    width: 100%;
                    .video_title {
                        background: url("../../../../Styles/Images/Media/video_tile.jpg");
                        width: 681px;
                        height: 34px;
                        h3 {
                            font-size: 100%;
                            color: $titleColor;
                            margin: 0;
                            height: 100%;
                            padding-left: 10px;
                            padding-top: 8px;
                        }
                    }
                    .playlist_video_video_controls {
                        @include flex(row, space-around);
                        width: 100%;
                        .playlist_video_container {
                            background: url("../../../../Styles/Images/Media/video_player_tile.jpg");
                            width: 681px;
                            height: 410px;
                            margin-bottom: 20px;
                            background-size: contain;
                            background-repeat: no-repeat;
                            .embedded_playlist {
                                width: 678px;
                                height: 404px;
                                padding-left: 4px;
                                padding-top: 4px;
                            }
                        }
                        .playlist_btn {
                            width: 80px;
                            height: 198px;
                            border: none;
                            background-position: 0px -198px !important;
                            margin-bottom: 55px;
                            &:hover {
                                cursor: pointer;
                            }
                            &:disabled {
                                background-position: 0px 0px !important;
                            }
                        }
                        .playlist_previous_btn {
                            background-image: url("../../../../Styles/Images/Media/video_previous.jpg");
                        }
                        .playlist_next_btn {
                            background-image: url("../../../../Styles/Images/Media/video_next.jpg");
                        }
                    }
                }
            }
        }
    }
}