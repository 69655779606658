@import "../../Styles/mixins.scss";
@import "../../Styles/variables.scss";

.admin_main {
    width: 970px;
    max-width: 970px;
}

.admin_new_user_content {
    p {
        margin-bottom: 20px;
    }
    .admin_new_user_sign_out {
        background: $btnPrimaryBackground;
        padding: 5px;
        border-radius: 6px;
        a {
            display: inline-block;
            color: $contentColor;
            width: 85px;
            text-decoration: none;
            &:hover {
                cursor: pointer;
            }
        }
    }

}

.admin_panel {
    position: relative;
    z-index: 1;
    text-align: left;
    margin-bottom: 100px;
    .admin_header {
        background: url("../../Styles/Images/Panel/title.jpg");
        color: $titleColor;
        padding: 10px;
    }
}

.admin_panel_content {
    @include flex(row, flex-start, flex-start);
    background: url("../../Styles/Images/Panel/middle.jpg");
    max-width: 970px;
    nav {
        height: 90%;
        width: 15%;
        margin-left: 10px;
        margin-top: 10px;
        color: $titleColor;
        border-right: 1px solid $white;
    }
    .admin_nav_btn {
        padding: 10px;
        border-radius: 6px;
        width: 100%;
        margin-bottom: 5px;
        &:hover {
            cursor: pointer;
            background: $btnPrimaryBackground;
        }
    }
    .admin_nav_btn_sign_out {
        padding: 10px;
        border-radius: 6px;
        width: 100%;
        margin-bottom: 5px;
        a {
            display: block;
            color: $contentColor;
            width: 105%;
            &:hover {
                cursor: pointer;
                background: $btnPrimaryBackground;
            }
        }
    }
    .admin_active {
        background: $btnPrimaryBackground;
    }
    .admin_main_content {
        width: 75%;
        padding-top: 40px;
        padding-left: 40px;
        border-left: 1px solid $white;
    }
}

.admin_panel_bottom {
    background: url("../../Styles/Images/Panel/bottom_none.jpg");
    width: 100%;
    height: 5vh;
}

.admin_spinner_container {
    position: relative;
    font-size: 13px;
    transition: all 1s;
}