@import "./variables.scss";

// Display flex
@mixin flex($direction: row, $justify: center, $align: center) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

// Border
@mixin border($color: $black) {
    border: 1px solid $color;
}

// Box shadow
@mixin shadow($color: $black) {
    -webkit-box-shadow: 2px 5px 13px -3px $color;
    -moz-box-shadow: 2px 5px 13px -3px $color;
    box-shadow: 2px 5px 13px -3px $color;
}