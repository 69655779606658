@import "../../../../../../../../Styles/mixins.scss";
@import "../../../../../../../../Styles/variables.scss";

.admin_home_tiles {
    width: 100%;
    .admin_store_new_btn_container {
        @include flex(row, flex-end);
        width: 100%;
        margin-bottom: 10px;
        button {
            background: $white;
            border: none;
            width: 85px;
            height: 30px;
            border-radius: 6px;
            &:hover {
                cursor: pointer;
                background-color: $offWhite;
            }
            &:focus {
                outline: none;
            }
        }
    }
    .admin_home_tiles_back_to_containers_container {
        width: 100%;
        button {
            background: $white;
            border: none;
            width: 85px;
            height: 30px;
            border-radius: 6px;
            &:hover {
                cursor: pointer;
                background-color: $offWhite;
            }
            &:focus {
                outline: none;
            }
        }
    }
}